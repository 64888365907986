import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { CheckCircleOutlined } from '@ant-design/icons';

const getNextType = (type) => {
	let nextType = '';
	if (type === 'Cash') {
		nextType = 'Cheque';
	} else if (type === 'Cheque') {
		nextType = 'UPI';
	} else if (type === 'UPI') {
		nextType = 'NEFT';
	}
	return nextType;
};

function ShowSuccessModal({ isSuccessModalOpen, setIsSuccessModalOpen, type }) {
	return (
		<Modal
			open={isSuccessModalOpen}
			onCancel={() => setIsSuccessModalOpen(false)}
			style={{ textAlign: 'center' }}
		>
			<CheckCircleOutlined />
			<p style={{ fontSize: '18px' }}>{type} Verification Updated Successfully</p>
			{type !== 'NEFT' && (
				<p style={{ fontSize: '14px' }}>
					Navigating to {getNextType(type)} Verification...
				</p>
			)}
		</Modal>
	);
}
export default ShowSuccessModal;

ShowSuccessModal.propTypes = {
	isSuccessModalOpen: PropTypes.bool,
	setIsSuccessModalOpen: PropTypes.func,
	type: PropTypes.string,
};
