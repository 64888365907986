import React from 'react';
import PropTypes from 'prop-types';
import { CashierSalesManStatsHeader, NEFTList } from 'components/pages/cashier/block-edits-v2';
export function NEFTVerification({ handleSaveClick, stepsData }) {
	return (
		<div>
			<CashierSalesManStatsHeader />
			<NEFTList handleSaveClick={handleSaveClick} stepsData={stepsData} />
		</div>
	);
}

NEFTVerification.propTypes = {
	handleSaveClick: PropTypes.func,
	stepsData: PropTypes.any,
};
