/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EyeFilled, WarningOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import { Image, Flex, Box } from 'atoms';
import greenTick from 'assets/svg/green-tick.svg';
import greenTickFill from 'assets/svg/green-tick-fill.svg';
import redClose from 'assets/svg/red-close.svg';
import redCloseFill from 'assets/svg/red-close-fill.svg';
import greenTickDisabled from 'assets/svg/GreenTick-disabled.svg';
import redCloseDisabled from 'assets/svg/red-close-disabled.svg';
import ReasonSelectModal from './ReasonSelectModal';
import RejectedDetails from 'pages/cashier/block-edits-v2/RejectedDetails';
import { theme } from 'styles/theme';

export const StyledPopover = styled(Popover)`
	.ant-popover-inner-content {
		padding: 0px !important;
	}
`;

const StyledButton = styled.button`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #0094ff;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #0094ff;
`;

export default function CashierVerificationFlow({
	record,
	handleVerifyReject,
	showRejection = false,
	remarksType = '',
	selected = false,
	showVerification = true,
	disabled = false,
}) {
	const [modalVisible, setModalVisible] = useState(false);

	const BoxCB = styled(Box)`
		max-width: 135px;
		text-align: center;
		background: #e8dddd;
		color: #701b1b;
		font-weight: 700;
		padding: 2px;
	`;

	return (
		<Flex style={{ gap: 10 }}>
			{record?.errorMessage !== '' && (
				<Box fontSize="15px" style={{ color: theme.colors.error }}>
					<Tooltip title={record.errorMessage}>
						<Box>
							<WarningOutlined />
						</Box>
					</Tooltip>
				</Box>
			)}
			{record?.payment_verification_status === 'ChequeBounced' && (
				<BoxCB> Cheque Bounced </BoxCB>
			)}
			{selected ? (
				<>
					{showVerification && (
						<Box className={disabled ? 'disabled-div' : ''}>
							<Image
								width={24}
								height={24}
								src={
									record.verification_checked ? greenTickFill : greenTickDisabled
								}
								alt="greenTick"
							/>
						</Box>
					)}
					{!record.verification_checked && (
						<Box className={disabled ? 'disabled-div' : ''}>
							<Image
								width={24}
								height={24}
								src={record?.rejection_checked ? redCloseFill : redCloseDisabled}
								alt="redClose"
							/>
						</Box>
					)}
				</>
			) : (
				<>
					{showVerification && record?.payment_verification_status !== 'ChequeBounced' && (
						<Box cursor="pointer" className={disabled ? 'disabled-div' : ''}>
							<Image
								width={24}
								height={24}
								src={record?.verification_checked ? greenTickFill : greenTick}
								alt="greenTick"
								onClick={() => handleVerifyReject(record, 'verify')}
							/>
						</Box>
					)}
					{showRejection &&
						!disabled &&
						record?.payment_verification_status !== 'ChequeBounced' && (
							<Box cursor="pointer" className={disabled ? 'disabled-div' : ''}>
								{/* Based on showRejection values, show only the verified status button, don't show the Rejected status  */}
								<Image
									width={24}
									height={24}
									src={record?.rejection_checked ? redCloseFill : redClose}
									alt="redClose"
									onClick={() => setModalVisible(true)}
								/>
							</Box>
						)}
					{record?.previous_data ? (
						<Box cursor="pointer" className={disabled ? 'disabled-div' : ''}>
							<StyledPopover
								content={
									<RejectedDetails
										payment_type={record?.payment_type}
										cashierData={record}
									/>
								}
								trigger="click"
								placement="bottomRight"
							>
								<StyledButton>
									<EyeFilled style={{ color: 'white' }} />
								</StyledButton>
							</StyledPopover>
						</Box>
					) : null}
				</>
			)}

			<ReasonSelectModal
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				record={record}
				remarksType={remarksType}
				successCallBack={(rejectReason = '', reject_reason = '') => {
					handleVerifyReject(record, 'reject', rejectReason, reject_reason);
				}}
			/>
		</Flex>
	);
}

CashierVerificationFlow.propTypes = {
	record: PropTypes.any,
	handleVerifyReject: PropTypes.func,
	showRejection: PropTypes.bool,
	remarksType: PropTypes.string,
	selected: PropTypes.any,
	showVerification: PropTypes.bool,
	disabled: PropTypes.bool,
};
