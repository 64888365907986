import React, { useState } from 'react';
import { Popconfirm, Popover } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Image, Flex, Box, Text } from 'atoms';
import greenTick from 'assets/svg/green-tick.svg';
import greenTickFill from 'assets/svg/green-tick-fill.svg';
import redClose from 'assets/svg/red-close.svg';
import redCloseFill from 'assets/svg/red-close-fill.svg';
import greenTickDisabled from 'assets/svg/GreenTick-disabled.svg';
import redCloseDisabled from 'assets/svg/red-close-disabled.svg';
import { RemarksForm } from 'components/pages/segregator/block-edits-v2';

export const StyledPopover = styled(Popover)`
	.ant-popover-inner-content {
		padding: 0px !important;
	}
`;

export default function Verification({
	record,
	handleVerifyReject,
	showRejection = false,
	remarksType = '',
	selected = false,
	showVerification = true,
	disabled = false,
}) {
	const [showRemarksForm, setShowRemarksForm] = useState(false);
	const { role } = useSelector((state) => state.auth);

	function handleShowRemarksForm() {
		setShowRemarksForm(true);
	}

	function hideShowRemarksForm() {
		setShowRemarksForm(false);
	}

	const confirm = () => {
		handleShowRemarksForm();
	};

	const disabledVerification = () =>
		selected && (
			<Flex
				justifyContent={showRejection && !disabled ? 'space-between' : 'center'}
				width="75px"
				className={disabled ? 'disabled-div' : ''}
			>
				{showVerification && (
					<Image
						width={24}
						height={24}
						src={record.verification_checked ? greenTickFill : greenTickDisabled}
						alt="greenTick"
					/>
				)}
				{!record.verification_checked && (
					<Image
						width={24}
						height={24}
						src={record?.rejection_checked ? redCloseFill : redCloseDisabled}
						alt="redClose"
					/>
				)}
			</Flex>
		);

	const BoxCB = styled(Box)`
		max-width: 135px;
		text-align: center;
		background: #e8dddd;
		color: #701b1b;
		font-weight: 700;
		padding: 2px;
	`;

	return (
		<>
			{record?.payment_verification_status === 'ChequeBounced' && (
				<BoxCB> Cheque Bounced </BoxCB>
			)}
			{!selected && (
				<Flex
					justifyContent={showRejection && !disabled ? 'space-between' : 'center'}
					width="75px"
					cursor="pointer"
					className={disabled ? 'disabled-div' : ''}
				>
					{showVerification &&
						record?.payment_verification_status !== 'ChequeBounced' && (
							<Image
								width={24}
								height={24}
								src={record?.verification_checked ? greenTickFill : greenTick}
								alt="greenTick"
								onClick={() => handleVerifyReject(record, 'verify')}
							/>
						)}

					{/* Based on showRejection values, show only the verified status button, don't show the Rejected status  */}
					{showRejection &&
						!disabled &&
						record?.payment_verification_status !== 'ChequeBounced' && (
							<Popconfirm
								title={
									<Box>
										<Text fontWeight="bold">
											Please provide reason to reject the invoice
										</Text>
										{role === 'Segregator' && (
											<div>
												<Text>Invoice status will be Rejected for</Text>
												<Text>this invoice in Collection Application</Text>
											</div>
										)}
									</Box>
								}
								onConfirm={confirm}
								okText="Add Reason"
								cancelText="Cancel"
							>
								<Image
									width={24}
									height={24}
									src={record?.rejection_checked ? redCloseFill : redClose}
									alt="redClose"
								/>
							</Popconfirm>
						)}
				</Flex>
			)}
			{disabledVerification()}
			<RemarksForm
				record={record}
				remarksType={remarksType}
				show={showRemarksForm}
				onHide={hideShowRemarksForm}
				successCallBack={(rejectReason = '') => {
					handleVerifyReject(record, 'reject', rejectReason);
				}}
			/>
		</>
	);
}

Verification.propTypes = {
	record: PropTypes.any,
	handleVerifyReject: PropTypes.func,
	showRejection: PropTypes.bool,
	remarksType: PropTypes.string,
	selected: PropTypes.any,
	showVerification: PropTypes.bool,
	disabled: PropTypes.bool,
};
