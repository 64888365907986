import React, { useState } from 'react';
import { Input, Modal, Radio, Space, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Text } from 'atoms';
import { saveRemarks } from 'store/invoiceSlice';

const StyledButton = styled(Button)`
	width: 130px;
	border-radius: 4px;
	margin-left: 0px;
	height: 40px;
`;
const StyledText = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 20px;
`;
const StyledTag = styled(Tag)`
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 15px;
`;
const StyledLabelWrapper = styled.label`
	width: 100%;
	input {
		background-color: #fff;
		margin-top: 7px;
		margin-bottom: 30px;
		border: none;
	}
`;

const StyledSpan = styled.span`
	font-size: 14px;
	font-weight: 400;
`;

const StyledModal = styled(Modal)`
	.ant-radio-checked .ant-radio-inner {
		border-color: #ca5010;
	}
	.ant-radio-inner::after {
		background-color: #ca5010;
	}
`;

export default function ReasonSelectModal({
	modalVisible,
	width = '60%',
	setModalVisible,
	remarksType,
	record,
	successCallBack,
}) {
	const [value, setValue] = useState('amountDifference');
	const [input, setInput] = useState('');
	const dispatch = useDispatch();

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const handleSubmit = () => {
		dispatch(
			saveRemarks({
				remark_status: remarksType,
				brand_id: record?.brand || null,
				collected_person_id: record?.collected_person_id || null,
				allocation_date_time: record?.allocation_date_time,
				remark: input,
				reject_reason: value,
				unique_key_code: record?.unique_key || '',
				collection_invoice_id: record?.cid ? record?.cid : record?.invoice_ids || 0,
				payment_ids: record?.payment_ids || 0,
			})
		).then(() => {
			successCallBack(input, value);
		});
		setModalVisible(false);
	};

	return (
		<StyledModal
			width={width}
			visible={modalVisible}
			footer={null}
			onCancel={() => setModalVisible(false)}
		>
			<Flex justifyContent="space-between">
				<StyledText>Please select reason for Rejection</StyledText>
			</Flex>

			<Radio.Group onChange={onChange} value={value}>
				<Space direction="vertical" style={{ width: '100%' }}>
					<Box>
						<Radio style={{ width: '100%', marginBottom: '7px' }} value="EDITDATA">
							<StyledSpan>Wrong Data Issue</StyledSpan>
						</Radio>
						<StyledTag bordered={false} color="orange">
							Select this option if salesman wants to change Cheque No , Date, Bank
							Name or UPI/Online Transaction Reference No.
						</StyledTag>
					</Box>
					<Box>
						<Radio style={{ width: '100%', marginBottom: '7px' }} value="EDITAMOUNT">
							<StyledSpan>Amount Difference Issue</StyledSpan>
						</Radio>
						<StyledTag bordered={false} color="orange">
							Select this option if there is difference in amount or salesman want to
							change amount.
						</StyledTag>
					</Box>
					<Box>
						<Radio
							style={{ width: '100%', marginBottom: '7px' }}
							value="EDITAMOUNTORDATA"
						>
							<StyledSpan>Both</StyledSpan>
						</Radio>
						<StyledTag bordered={false} color="orange">
							Select this option if salesman wants to change both amount and other
							related details.
						</StyledTag>
					</Box>
				</Space>
			</Radio.Group>
			<StyledLabelWrapper>
				<StyledSpan style={{ marginBottom: '7px' }}>Remarks(Optional)</StyledSpan>
				<Input
					placeholder="Type your comment here..."
					onChange={(e) => setInput(e.target.value)}
				/>
			</StyledLabelWrapper>

			<StyledButton variant="primary" onClick={handleSubmit}>
				Submit
			</StyledButton>
		</StyledModal>
	);
}

ReasonSelectModal.propTypes = {
	modalVisible: PropTypes.bool,
	setModalVisible: PropTypes.func,
	width: PropTypes.number,
	remarksType: PropTypes.string,
	record: PropTypes.object,
	successCallBack: PropTypes.func,
};
