import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
	CashierSalesManStatsHeader,
	CashVerificationTable,
	CashVerifyHeader,
} from 'components/pages/cashier/block-edits-v2';
import { getcashierVerificationList } from 'store/cashierSlice';

export function CashVerification({ stepsData, handleSaveClick }) {
	const [headerData, setHeaderData] = useState(0);
	const dispatch = useDispatch();
	const allocated_date = headerData?.data_summary?.allocation_date_time;
	const collected_person_id = headerData?.data_summary?.collected_person_id;
	const tab = headerData?.data_summary?.tab;
	const [cashierData, setcashierData] = useState({});
	const [rejectedReason, setRejectedReason] = useState(null);
	const [isCashVerified, setIsCashVerified] = useState({
		verification_checked: false,
		rejection_checked: false,
	});
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);

	useEffect(() => {
		if (collected_person_id && allocated_date && tab)
			dispatch(getcashierVerificationList(collected_person_id, allocated_date, tab)).then(
				(res) => {
					console.log(res);
					if (res) {
						setcashierData(res);
					}
				}
			);
	}, [dispatch, allocated_date, tab, collected_person_id]);

	useEffect(() => {
		setIsCashVerified({
			verification_checked: cashierData?.verification_checked || false,
			rejection_checked: cashierData?.rejection_checked || false,
		});
	}, [cashierData?.rejection_checked, cashierData?.verification_checked]);

	function handleVerifyReject(_, type = 'verify', rejectedReasonData = null) {
		let greenTick = false;
		let redTick = isCashVerified?.rejection_checked ?? false;
		if (type === 'verify' && !isCashVerified?.verification_checked) {
			greenTick = true;
			redTick = false;
		} else if (type === 'reject' && !isCashVerified?.rejection_checked) {
			greenTick = false;
			redTick = true;
		}
		setIsCashVerified({
			verification_checked: greenTick,
			rejection_checked: redTick,
		});
		setRejectedReason(rejectedReasonData);
	}

	return (
		<div>
			<CashierSalesManStatsHeader setHeaderData={setHeaderData} />
			<CashVerifyHeader
				record={{
					...isCashVerified,
					...cashierPersonHeaderDetails?.data_summary,
					remark_flag: headerData?.payment_remark_flag || false,
				}}
				handleVerifyReject={handleVerifyReject}
				totalCash={headerData?.data?.total_cash || 0}
				colleted_pid={collected_person_id}
				allocated_date={allocated_date}
				tab={tab}
				isCashVerified={isCashVerified}
				stepsData={stepsData}
			/>
			<CashVerificationTable
				isCashVerified={isCashVerified}
				rejectedReason={rejectedReason}
				stepsData={stepsData}
				handleSaveClick={handleSaveClick}
			/>
		</div>
	);
}

CashVerification.propTypes = {
	stepsData: PropTypes.object,
	handleSaveClick: PropTypes.any,
};
