/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input, Table } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Box, Flex, Button } from 'atoms';
import { Rupee, RejectedListModal } from 'components';
import { getDenominations, saveCashSettlement } from 'store/invoiceSlice';
import { useURL } from 'hooks';
import ShowSuccessModal from 'components/modals/ShowSuccessModal';

function getTotal(allData, type) {
	return allData.reduce((acc, curr) => {
		if (curr?.[type]) {
			return acc + curr[type] * curr.amount;
		}
		return acc + 0;
	}, 0);
}

const TableConfigs = ({ cashierVerify = true, handleOnChange, tableData = [] }) => [
	{
		cashierVerify,
		title: <TH>Amount</TH>,
		dataIndex: 'amount',
		key: 'amount',
		render: (amount, rec) =>
			!rec?.isLastElement && (
				<Box textAlign="center" color="#000">
					<Rupee>{amount}</Rupee>
				</Box>
			),
	},
	{
		cashierVerify,
		title: <TH>Salesperson Entered</TH>,
		dataIndex: 'salespersonEntered',
		key: 'salespersonEntered',
		render: (salespersonEntered, rec) =>
			!rec?.isLastElement && (
				<SalesPersonAmount textAlign="center" color="#000">
					{salespersonEntered || 0}
				</SalesPersonAmount>
			),
	},
	{
		cashierVerify,
		title: <TH>Amount</TH>,
		dataIndex: 'id',
		key: 'id',
		render: (id, rec) => (
			<Box textAlign="center" color="#000">
				{rec?.isLastElement && 'Total: '}
				<Rupee>
					{rec?.isLastElement
						? getTotal(tableData, 'salespersonEntered')
						: rec.amount * rec.salespersonEntered || 0}
				</Rupee>
			</Box>
		),
	},
	{
		cashierVerify,
		title: <TH>Cashier Entered</TH>,
		dataIndex: 'cashierEntered',
		key: 'cashierEntered',
		render: (cashierEntered, rec) =>
			!rec.isLastElement && (
				<Flex justifyContent="center" alignItems="center">
					<AmountButton onClick={() => handleOnChange('minus', rec)}>-</AmountButton>
					<AmountInput
						onChange={(e) => handleOnChange(e.target.value, rec)}
						value={cashierEntered}
					/>
					<AmountButton
						onClick={() => handleOnChange('plus', rec)}
						style={{ background: '#352A35' }}
						color="#fff"
					>
						+
					</AmountButton>
				</Flex>
			),
	},
	{
		cashierVerify,
		title: <TH>Amount</TH>,
		dataIndex: 'id',
		key: 'id',
		render: (id, rec) => (
			<Box textAlign="center" color="#000" minWidth="100px">
				{rec?.isLastElement && 'Total: '}
				<Rupee>
					{rec?.isLastElement
						? getTotal(tableData, 'cashierEntered')
						: rec.amount * rec.cashierEntered || 0}
				</Rupee>
			</Box>
		),
	},
];

export function CashVerificationTable({
	isCashVerified,
	rejectedReason,
	handleSaveClick,
	stepsData,
}) {
	const dispatch = useDispatch();
	const [tableData, setTableData] = useState([]);
	const [isVerified, setIsVerified] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rejectedList, setRejectedList] = useState([]);
	const urlData = useURL();
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	useEffect(() => {
		setIsVerified(
			cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.Cash &&
				cashierPersonHeaderDetails?.data_summary?.payment_verification_status
					?.cash_verification_checked
		);
	}, [cashierPersonHeaderDetails]);

	useEffect(() => {
		dispatch(
			getDenominations(
				omit(urlData, [
					'verified_by_cashier_status',
					'brand_id',
					'verified_by_segregator_status',
					'queryString',
				])
			)
		).then((res) => {
			setTableData([
				{
					id: 1,
					amount: 2000,
					salespersonEntered: res?.data?.[0]?.two_thousand || 0,
					cashierEntered: 0,
				},
				{
					id: 2,
					amount: 500,
					salespersonEntered: res?.data?.[0]?.five_hundred || 0,
					cashierEntered: 0,
				},
				{
					id: 3,
					amount: 200,
					salespersonEntered: res?.data?.[0]?.two_hundred || 0,
					cashierEntered: 0,
				},
				{
					id: 4,
					amount: 100,
					salespersonEntered: res?.data?.[0]?.hundred || 0,
					cashierEntered: 0,
				},
				{
					id: 5,
					amount: 50,
					salespersonEntered: res?.data?.[0]?.fifty || 0,
					cashierEntered: 0,
				},
				{
					id: 6,
					amount: 20,
					salespersonEntered: res?.data?.[0]?.twenty || 0,
					cashierEntered: 0,
				},
				{
					id: 7,
					amount: 10,
					salespersonEntered: res?.data?.[0]?.ten || 0,
					cashierEntered: 0,
				},
				{
					id: 8,
					amount: 5,
					salespersonEntered: res?.data?.[0]?.five || 0,
					cashierEntered: 0,
				},
				{
					id: 9,
					amount: 1,
					salespersonEntered: res?.data?.[0]?.coins || 0,
					cashierEntered: 0,
					isCoins: 'Coins',
				},
				{
					id: 10,
					isLastElement: true,
				},
			]);
		});
	}, [dispatch, urlData]);

	const handleOnChange = (e, record) => {
		if (e === 'plus') {
			record.cashierEntered += 1;
		} else if (e === 'minus' && record.cashierEntered > 0) {
			record.cashierEntered -= 1;
		} else if ((Number(e) || Number(e) === 0) && Number(e) <= 10000) {
			record.cashierEntered = Number(e);
		}
		const newTableData = tableData?.map((item) => {
			if (item?.id === record?.id) {
				return record;
			}
			return item;
		});
		setTableData([...newTableData]);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	function handleCashVerification() {
		dispatch(
			saveCashSettlement({
				...urlData,
				cash_verification_checked: isCashVerified?.verification_checked,
				cash_rejection_checked: isCashVerified?.rejection_checked,
				...(rejectedReason && {
					reject_remark: {
						remark_status: 'CASH_PAYMENT_REJECT',
						brand_id: urlData.brand_id,
						remark: rejectedReason,
						unique_key_code: '',
						reject_reason: 'EDITAMOUNT',
					},
				}),
			})
		).then(() => {
			setIsSuccessModalOpen(true);
			handleSaveClick();
		});
	}

	return (
		<div>
			<Table
				rowKey="id"
				bordered
				columns={TableConfigs({ handleOnChange, tableData })}
				dataSource={tableData}
				pagination={false}
			/>
			<Flex justifyContent="flex-end" mt="2rem">
				<Button
					onClick={handleCashVerification}
					disabled={
						stepsData?.cashier_actions?.cash_action ||
						isVerified ||
						(!isCashVerified?.verification_checked &&
							!isCashVerified?.rejection_checked)
					}
				>
					{isVerified ? 'Verified' : 'Save'}
				</Button>
				<RejectedListModal
					visible={isModalOpen}
					rejectedList={rejectedList}
					onCancel={handleCancel}
					confirmRejection={handleCashVerification}
				/>
				<ShowSuccessModal
					isSuccessModalOpen={isSuccessModalOpen}
					setIsSuccessModalOpen={setIsSuccessModalOpen}
				/>
			</Flex>
		</div>
	);
}

const TH = styled.div`
	color: #646464;
	font-size: 12px;
	text-align: center;
`;

const SalesPersonAmount = styled(Box)`
	width: 40%;
	margin: auto;
	background: #fdfdfd;
	border: 1px solid #d8d8d8;
	text-align: center;
	font-size: 12px;
	color: #646464;
`;

const AmountInput = styled(Input)`
	width: 120px;
	margin: 0 15px;
	height: 26px;
	text-align: center;
`;

const AmountButton = styled(Box)`
	width: 24px;
	height: 24px;
	background: #fdfdfd;
	border: 0.5px solid #352a35;
	border-radius: 2px;
	text-align: center;
	cursor: pointer;
`;

CashVerificationTable.propTypes = {
	isCashVerified: PropTypes.object,
	rejectedReason: PropTypes.any,
	handleSaveClick: PropTypes.func,
	stepsData: PropTypes.object,
};
