/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popover, Button, Tooltip } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text } from 'atoms';
import { Rupee } from 'components';
import { RemarksIcon } from 'components/pages/segregator';
import { Verification } from 'components/pages/segregator/block-edits-v2';
import { useURL } from 'hooks';
import { getcashierVerificationList } from 'store/cashierSlice';
import RejectedDetails from 'pages/cashier/block-edits-v2/RejectedDetails';

const StyledButton = styled(Button)`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #0094ff;
	margin-left: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default function CashVerifyHeader({
	handleVerifyReject,
	record = {},
	colleted_pid,
	allocated_date,
	tab,
	stepsData,
}) {
	const urlData = useURL();
	const dispatch = useDispatch();
	const [cashierData, setcashierData] = useState({});
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);
	useEffect(() => {
		if (colleted_pid && allocated_date && tab)
			dispatch(getcashierVerificationList(colleted_pid, allocated_date, tab)).then((res) => {
				console.log(res);
				setcashierData(res);
			});
	}, [dispatch, colleted_pid, allocated_date, tab]);

	return (
		<Container>
			<Flex width="60%" alignItems="center">
				<Title>Total Cash Collected: </Title>
				<Text
					as="div"
					fontSize="16px"
					fontWeight="bold"
					style={{ display: 'flex', alignItems: 'center' }}
				>
					<Rupee>{cashierData?.total_cash}</Rupee>
					{cashierData?.previous_data ? (
						<StyledPopover
							content={
								<RejectedDetails payment_type="Cash" cashierData={cashierData} />
							}
							trigger="click"
							placement="bottomRight"
						>
							<Tooltip title="Click to view changes">
								<StyledButton>
									<EyeFilled style={{ color: 'white', fontSize: '20px' }} />
								</StyledButton>
							</Tooltip>
						</StyledPopover>
					) : null}
				</Text>
			</Flex>
			<Flex width="40%" justifyContent="space-between">
				<Flex alignItems="center">
					<Title>Verification</Title>
					<Verification
						record={record}
						handleVerifyReject={handleVerifyReject}
						showRejection
						remarksType="CASH_PAYMENT_REJECT"
						disabled={
							cashierPersonHeaderDetails?.data_summary?.payment_verification_status
								?.cash_verification_checked ||
							stepsData?.cashier_actions?.cash_action
						}
					/>
				</Flex>
				<Flex alignItems="center">
					<Title>Remarks</Title>
					<RemarksIcon record={{ ...urlData, ...record }} type="CASH_PAYMENT" />
				</Flex>
			</Flex>
		</Container>
	);
}

export const Container = styled(Flex)`
	justify-content: space-between;
	border: 1px solid ${(props) => props.theme.colors.Gainsboro};
	background-color: ${(props) => props.theme.colors.white};
	padding: 15px 25px;
	margin-bottom: 1.5rem;
`;

export const Title = styled(Text)`
	font-weight: 400;
	font-size: 14px;
	margin-right: 1.5rem;
`;
export const StyledPopover = styled(Popover)`
	.ant-popover-inner-content {
		padding: 0px !important;
	}
`;

CashVerifyHeader.propTypes = {
	record: PropTypes.object,
	handleVerifyReject: PropTypes.func,
	colleted_pid: PropTypes.number,
	allocated_date: PropTypes.string,
	tab: PropTypes.number,
	stepsData: PropTypes.any,
};
