/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Table, Modal, message as toaster, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import greenTick from 'assets/svg/green-tick-filled.svg';
import { RemarksIcon } from 'components/pages/segregator';
import { Rupee } from 'components';
import {
	getChequesUPIList,
	saveChequesOrUPIList,
	upiBulkVerify,
	updateUpiStatus,
} from 'store/invoiceSlice';
import { Flex, Button, Text, Box } from 'atoms';
import { useURL } from 'hooks';
import { getunique, unScReplace } from 'utils/text';
import { getGreenRedTicksV2, getTransactionsV2 } from 'utils/helper';
import { theme } from 'styles/theme';
import CashierVerificationFlow from './CashierVerificationFlow';

export const VerifyButton = styled(Button)`
	float: right;
	margin-top: 5px;
	margin-bottom: 10px;
`;
export const VerifyButtonInactive = styled(Button)`
	float: right;
	margin-top: 5px;
	background-color: grey;
	margin-bottom: 10px;
`;
const ButtonContainer = styled(Flex)`
	justify-content: center;
	margin-top: 2rem;
`;

const Count = styled(Text)`
	font-weight: 400px;
	font-size: 24px;
	margin: 7px 0 7px 0;
`;
const TextOne = styled(Text)`
	font-weight: 400px;
	font-size: 20px;
`;
const TextTwo = styled(Text)`
	font-weight: 400px;
	font-size: 14px;
`;
const FIlterSection = styled.div`
	margin-bottom: 20px;
`;
export const Input = styled.input`
	height: 40px;
	width: 300px;
	background: #faf9f8;
	border: 1px solid #edebe9;
	border-radius: 2px;
	margin-top: 7px;
	padding: 8px 16px 8px 16px;
	margin-right: 24px;
`;

const getVerificationHash = (element) =>
	`${element.verification_checked ? 'v' : 'nv'}|${element.rejection_checked ? 'r' : 'nr'}`;

export default function UPIList({ handleSaveClick, stepsData }) {
	const dispatch = useDispatch();
	const [invoices, setInvoices] = useState([]);
	const [isValid, setIsValid] = useState(false);
	const [rowSelect, setrowSelect] = useState([]);
	const urlData = useURL();
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);
	const [showPopup, setShowPopup] = useState();
	const { auth } = useSelector((state) => state?.auth);
	const [verifiedData, setVerifiedData] = useState(null);
	const [modificationCount, setModificationCount] = useState(0);
	const [tableParams, setTableParams] = useState({
		pagination: { current: 1, pageSize: 50 },
	});

	useEffect(() => {
		setIsValid(
			invoices?.every((item) => item?.verification_checked || item?.rejection_checked)
		);
	}, [invoices]);

	useEffect(() => {
		const data = {
			...urlData,
			payment_type: 'UPI',
		};
		getInvoiceListCall(tableParams, data);
		// eslint-disable-next-line
	}, [dispatch, urlData]);

	function getInvoiceListCall(tableConfig, data) {
		dispatch(getChequesUPIList(tableConfig, data)).then((res) => {
			setTableParams({
				pagination: {
					current: tableConfig.pagination.current,
					pageSize: tableConfig.pagination.pageSize,
					total: res.count,
				},
			});

			res?.data?.forEach((element) => {
				element.disabled = element?.payment_verification_status_flag === true;
				element.verification_checked = element?.payment_verification_status_flag === true;
				element.rejection_checked =
					element?.payment_verification_status === 'RejectedByCashier';

				element.verification_hash = getVerificationHash(element);

				element.checked =
					element?.payment_verification_status_flag === false &&
					element.payment_verification_status === '' &&
					element.bank_statement_verification_status === '';
				element.page = 'UPI';
			});
			setrowSelect([]);
			setInvoices(res?.data);
			setModificationCount(0);
		});
	}

	function handleVerifyReject(
		record,
		type = 'verify',
		rejectedReason = null,
		rejected_Reason = null
	) {
		const records = invoices.map((item) => {
			if (item?.key === record?.key) {
				return getGreenRedTicksV2(type, item, rejectedReason, rejected_Reason);
			}
			return item;
		});

		const mod_records = records.filter((x) => {
			const h = getVerificationHash(x);
			return h !== x.verification_hash && h !== 'nv|nr';
		});

		setModificationCount(mod_records.length);
		setInvoices([...records]);
	}

	const rowSelection = {
		onChange: (selectedRowKeys) => {
			setrowSelect([...selectedRowKeys]);
		},
		getCheckboxProps: (record) => ({
			disabled:
				record.verification_checked ||
				(record?.bank_statement_verification_status !== '' &&
					record?.payment_verification_status === '' &&
					record.bank_statement_id !== '') ||
				record.bank_statement_id === '' ||
				record.errorMessage !== '' ||
				record.payment_verification_status === 'RejectedByCashier',
		}),
	};

	function handleVerificationCancel() {
		setShowPopup(null);
	}
	const bulkVerify = () => {
		const selected = invoices.filter((i) => rowSelect.find((x) => x === `${i.key}`));
		const userInfo = auth.user;
		dispatch(upiBulkVerify(selected, userInfo)).then((r) => {
			if (r.data.length > 0) {
				setShowPopup(true);
				setVerifiedData({ res: r.data, selected });
				console.log('condata', r.data);
			} else {
				toaster.error('0 Records Verified');
			}
		});
	};

	const updateUPIStatus = () => {
		const utrArr = verifiedData?.res?.map(({ utrNo }) => utrNo);

		const finalUpiList = verifiedData?.selected.filter((item) =>
			utrArr?.includes(item.referenceNumber)
		);
		setShowPopup(null);
		dispatch(
			updateUpiStatus(finalUpiList, auth.user, cashierPersonHeaderDetails?.data_summary)
		).then((r) => {
			if (r.message === 'success') {
				toaster.info('Successfully updated.');
				if (rowSelect.length === invoices.length) {
					handleSaveClick();
				} else {
					window.location.reload();
				}
			} else {
				toaster.info(r.message);
			}
		});
	};

	const columns = [
		{
			title: 'Invoice Number',
			dataIndex: 'invoice_nos',
			key: 'invoice_nos',
			render: (invoice_nos) => invoice_nos.split(',').map((si) => <Text key={si}>{si}</Text>),
		},
		{
			title: 'Store Name',
			dataIndex: 'storeName',
			key: 'storeName',
			filters: getunique(invoices, 'storeName'),
			onFilter: (value, record) => record.storeName.startsWith(value),
			filterSearch: true,
			render: (storeName) => <span>{unScReplace(storeName)}</span>,
		},
		{
			title: 'UPI Reference Number',
			dataIndex: 'referenceNumber',
			key: 'referenceNumber',
			render: (record) => <div>{record}</div>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (record) => <Rupee>{Number(record)}</Rupee>,
		},
		{
			title: 'Verification',
			dataIndex: 'verified_by_segregator_status',
			key: 'verified_by_segregator_status',
			render: (_, record) => (
				<CashierVerificationFlow
					record={{
						...record,
						collected_person_id:
							cashierPersonHeaderDetails?.data_summary?.collected_person_id,
						brand: cashierPersonHeaderDetails?.data_summary?.brand_name,
					}}
					handleVerifyReject={handleVerifyReject}
					showRejection
					remarksType="UPI_PAYMENT_REJECT"
					selected={rowSelect?.length > 0}
					showVerification={
						record.bank_statement_id !== '' &&
						!(
							record?.bank_statement_verification_status !== '' &&
							(record?.payment_verification_status === '' ||
								record?.payment_verification_status === 'RejectedByCashier') &&
							record.bank_statement_id !== ''
						)
					}
					disabled={record?.disabled}
				/>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'salesman_name',
			key: 'salesman_name',
			render: (_, record) => <RemarksIcon record={record} type="UPI_PAYMENT" />,
		},
	];

	function handleSaveUPIVerification() {
		dispatch(
			saveChequesOrUPIList({
				payment_type: 'UPI',
				collected_person_id: cashierPersonHeaderDetails?.data_summary?.collected_person_id,
				allocation_date_time:
					cashierPersonHeaderDetails?.data_summary?.allocation_date_time,
				cid: cashierPersonHeaderDetails?.data_summary?.cid,
				transactions: getTransactionsV2(invoices, urlData, 'UPI_PAYMENT_REJECT'),
			})
		).then(() => {
			handleSaveClick();
		});
	}

	const handleInvoiceTableChange = (pagination) => {
		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setInvoices([]);
		}

		const data = {
			...urlData,
			payment_type: 'UPI',
		};
		getInvoiceListCall(
			{
				pagination,
			},
			data
		);
	};

	return (
		<Box>
			<FIlterSection>
				<Box>
					{rowSelect.length > 0 && (
						<VerifyButton
							onClick={() => {
								bulkVerify();
							}}
						>
							Auto verify UPI payments
						</VerifyButton>
					)}
				</Box>
				<Box>
					{rowSelect.length <= 0 && (
						<VerifyButtonInactive>Auto verify UPI payments</VerifyButtonInactive>
					)}
				</Box>
			</FIlterSection>
			<Table
				rowKey={(record) => `${record?.key}`}
				dataSource={invoices}
				columns={columns}
				pagination={tableParams.pagination}
				rowSelection={{
					type: 'checkbox',
					preserveSelectedRowKeys: true,
					hideSelectAll: false,
					selectedRowKeys: rowSelect,
					...rowSelection,
				}}
				onChange={handleInvoiceTableChange}
			/>
			<Flex justifyContent="flex-end" mt="2rem">
				<Button
					onClick={handleSaveUPIVerification}
					disabled={
						invoices?.length === 0 ||
						!isValid ||
						stepsData?.cashier_actions?.upi_action ||
						modificationCount <= 0
					}
				>
					{stepsData?.cashier_actions?.upi_action ? 'Verified' : `Save`}
				</Button>
			</Flex>

			{verifiedData && (
				<Modal
					centered
					visible={showPopup}
					footer={null}
					onCancel={handleVerificationCancel}
					maskClosable={false}
				>
					<Box mt="3" textAlign="center">
						<img src={greenTick} alt="tick"></img>
						<Count>
							{verifiedData?.res?.length || 0}/{verifiedData.selected?.length}
						</Count>
						<TextOne>Records Found</TextOne>
						<TextTwo>Records will be sorted down.</TextTwo>
					</Box>
					<ButtonContainer>
						<Button
							color="#fff"
							border={0}
							variant="secondary"
							onClick={updateUPIStatus}
						>
							Update Status
						</Button>
					</ButtonContainer>
				</Modal>
			)}
		</Box>
	);
}

UPIList.propTypes = {
	handleSaveClick: PropTypes.func,
	stepsData: PropTypes.any,
};
