/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select, message as toaster } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import styled from 'styled-components';
import timeIcon from 'assets/icons/timeIcon.svg';
import {
	getSalesOrderList,
	getSaleStoreList,
	unBlockOrders,
} from 'store/fulfilment/salesOrderSlice';
import { Box, Flex, Button, AsyncSelect, Image } from 'atoms';
import { RCTable, SearchBar, LabelWrapper, LoadAsyncSelectComponent, ACL } from 'components/common';
import { handleFilterChange, unBlockOrderColumns } from 'utils/tableConfig';
import {
	toAsysnOption,
	SelectFilterLabelOptions,
	searchBox,
	initialPaginateConfigWithoutQ,
	UserPersmission,
	SalesOrderBlockedQueries,
	TODAYDDMMYYYY,
	PAST5DAYSDDMMYYYY,
} from 'utils';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';
import UnblockReasonModal from 'components/modals/UnblockReasonModal';

export const FilterByOptions = [
	{ value: 'fields', label: 'Search By' },
	{ value: 'statuses', label: 'Status(s)' },
	{ value: 'gst_number', label: 'GST' },
];

const MessageBox = styled.div`
	background: rgb(255, 251, 248);
	color: rgb(202, 80, 16);
	font-size: 12px;
	padding: 8px 16px;
	font-weight: 500;
	margin: 0 0 14px 0;
`;

const MessageBoxIcon = styled(Image)`
	margin-right: 8px;
	margin-bottom: -3px;
	height: 15px;
`;

export default function BlockedOrder() {
	const dispatch = useDispatch();
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rowSelect, setrowSelect] = useState([]);
	const [unlockDatas, setUnlockDatas] = useState([]);

	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: SalesOrderBlockedQueries,
	});
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);

	const rowSelection = {
		selectedRowKeys: rowSelect,
		preserveSelectedRowKeys: true,
		onChange: (selectedRowKeys) => {
			if (!rowData?.rows) {
				console.warn('No rowData available');
				return;
			}

			const selectedIdsSet = new Set(selectedRowKeys);

			// Merge selected rows and keep only selected items in the final data
			const finalMergedData = [
				...unlockDatas.filter((item) => selectedIdsSet.has(item.id)), // Keep items still selected
				...rowData.rows.filter(
					(item) =>
						selectedIdsSet.has(item.id) && !unlockDatas.some((m) => m.id === item.id)
				), // Add new selected items
			];

			setUnlockDatas(finalMergedData);
			setrowSelect([...selectedRowKeys]);
		},
	};

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [paginateConfig]);

	useEffect(() => {
		if (rowSelect?.length > 99) {
			toaster.error('Max 100 invoices selection completed');
		}
	}, [rowSelect]);

	const handleFCChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value
		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		dispatch(getSaleStoreList({ q: '**', fc_ids: fcValues })).then(() => {
			setpaginateConfig((prev) => ({
				...handleFilterChange(name, fcValues, prev),
				brands: [],
				stores: [],
			}));
		});
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = (value) => {
		const selectedData = unlockDatas.map((item) => {
			console.log('item ', item);
			return {
				order_id: item?.id,
				invoice_id: item?.invoice_no,
				invoice_data: `${item?.FC?.code}:${item?.Brand?.code}:${item?.invoice_no}`,
				status: item?.status,
				reason_id: value,
			};
		});
		dispatch(unBlockOrders(selectedData))
			.then(() => {
				toaster.success(
					`${rowSelect.length} ${
						rowSelect.length > 1 ? 'invoices' : 'invoice'
					} Unblocked Successfully`
				);
			})
			.catch((err) => toaster.error(`some error occured: ${err}`))
			.finally(() => {
				setIsModalOpen(false);
				setrowSelect([]);
				setUnlockDatas([]);
				setBtnClickSearh(Math.random());
			});
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<MessageBox>
				<MessageBoxIcon src={timeIcon} alt="edit-icon" />
				Only Past 5 Days ( {PAST5DAYSDDMMYYYY} to {TODAYDDMMYYYY} ) Blocked Orders will be
				available to Unblock.
			</MessageBox>
			<Flex alignItems="center">
				<Flex width="100%" flexWrap="wrap" gap="4px">
					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.fcs}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcs"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcs}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleFCChangeSelect('fcs', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							mode="multiple"
							value={paginateConfig?.brands}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>

					<Box marginLeft="1rem">
						<LabelWrapper label="Search by Invoice Number" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search by Invoice Number"
									queryValue={paginateConfig?.queryInvoice?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => ({
											...prev,
											limit: 10,
											offset: 0,
											currentPage: 1,
											queryInvoice: val,
										}))
									}
								/>
							</SearchField>
						</LabelWrapper>
					</Box>
				</Flex>
				<Flex style={{ gap: 16 }}>
					<Box style={{ width: '10rem' }}>
						<Button
							style={{ marginBottom: 16 }}
							onClick={() => setBtnClickSearh(Math.random())}
						>
							Search
						</Button>
					</Box>
					<Box>
						<ACL allowedGroups={UserPersmission.salesOrderunblock_list}>
							<Button
								onClick={showModal}
								style={{ background: '#107C10' }}
								disabled={isEmpty(rowSelect)}
							>
								Unblock
							</Button>
						</ACL>
					</Box>
				</Flex>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					rowSelection={{
						type: 'checkbox',
						hideSelectAll: 100 - rowSelect.length <= (paginateConfig?.pageSize || 10),
						...rowSelection,
					}}
					dispatchAction={getSalesOrderList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={unBlockOrderColumns({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>

			<UnblockReasonModal
				onCancel={handleCancel}
				modalVisible={isModalOpen}
				handleOk={handleOk}
				rowSelect={rowSelect}
				handleCancel={handleCancel}
			/>
		</>
	);
}
