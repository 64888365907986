/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Steps, Tooltip } from 'antd';
import { WarningFilled, WarningOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'atoms';
import RefreshIcon from 'components/common/RefreshIcon';
import { useURL } from 'hooks';
import { getCashierStateTransition, getInvoicesList } from 'store/invoiceSlice';
import { CashVerification } from './CashVerification';
import { ChequeVerification } from './ChequeVerification';
import { UpiVerification } from './UpiVerification';
import { NEFTVerification } from './NEFTVerification';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)`
	height: 50px;
	width: 50px;
	border-radius: 50%;
`;

const StyledDiv = styled.div`
	margin-bottom: 30px;
	.ant-steps {
		height: 100px;
	}
	.ant-steps-dot .ant-steps-item-tail,
	.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
		top: 23px;
	}
	.ant-steps-item .ant-steps-item-finish {
		background-color: orange;
	}
	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: #0a9600;
	}
	.ant-steps-item-title {
		left: 21px;
		top: 35px;
	}
`;

const StyledSpan = styled.span`
	color: #b2b2b2;
	font-size: 14px;
`;

const StyledText = styled(Text)`
	margin: 40px 0px 0px 20px;
	width: 100%;
`;

const { Step } = Steps;

const links = [
	{
		id: 0,
		number: 1,
		title: 'Cash verification',
		type: 'Cash',
		edit: false,
		payment_type: 'cash_action',
		edit_type: 'cash_edit',
	},
	{
		id: 1,
		number: 2,
		title: 'Cheque verification',
		type: 'Cheque',
		edit: false,
		payment_type: 'cheque_action',
		edit_type: 'cheque_edit',
	},
	{
		id: 2,
		number: 3,
		title: 'UPI',
		type: 'UPI',
		edit: false,
		payment_type: 'upi_action',
		edit_type: 'upi_edit',
	},
	{
		id: 3,
		number: 4,
		title: 'NEFT',
		type: 'NEFT',
		edit: false,
		payment_type: 'neft_action',
		edit_type: 'neft_edit',
	},
];

const getStyle = (status) => {
	let color = 'ADB5BD';
	if (status === 'finish') {
		color = '#0A9600';
	} else if (status === 'process') {
		color = '#CA5010';
	} else {
		color = '#ADB5BD';
	}
	return color;
};

function getIcon(data, index) {
	if (index === 0) {
		return data?.cash_reject;
	} else if (index === 1) {
		return data?.cheque_reject;
	} else if (index === 2) {
		return data?.upi_reject;
	} else if (index === 3) {
		return data?.neft_reject;
	}
}

const customDot = (dot, { status, index }, handleStepClick, noEdits, currentStatus) => {
	dot = (
		<StyledButton
			onClick={() => {
				if (status !== 'wait') {
					handleStepClick(index);
				}
			}}
			style={{ backgroundColor: `${getStyle(status)}`, color: 'white' }}
		>
			{index + 1}
			{noEdits && (status == 'wait' || status == 'process') && <RefreshIcon />}
			{!noEdits && getIcon(currentStatus?.reject_status, index) && (
				<WarningFilled
					style={{
						width: '18px',
						height: '18px',
						position: 'absolute',
						right: '-2px',
						top: '-5px',
						borderRadius: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'red',
					}}
				/>
			)}
		</StyledButton>
	);
	return status === 'wait' ? (
		<Tooltip
			trigger="click"
			placement="top"
			title={
				<StyledSpan>
					<WarningOutlined style={{ marginRight: '5px' }} />
					{status === 'wait' && 'Please verify previous payment first to proceed further'}
				</StyledSpan>
			}
		>
			{dot}
		</Tooltip>
	) : (
		dot
	);
};

function CashierStepperComponent() {
	const [current, setCurrent] = useState('');
	const [currentProxies, setCurrentProxies] = useState([]);
	const [currentProxy, setCurrentProxy] = useState();
	const [dataSummary, setDataSummary] = useState({});
	const [currentStatus, setCurrentStatus] = useState(null);
	const dispatch = useDispatch();
	const urlData = useURL();
	const [allVerified, setAllVerified] = useState(null);
	const [noEdits, setNoEdits] = useState(null);
	const [stepperData, setStepperData] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getInvoicesList(urlData)).then((res) => {
			setDataSummary(res?.data_summary);
		});
	}, [dispatch, urlData]);

	useEffect(() => {
		if (dataSummary?.collected_person_id) {
			const stateData = {
				collected_person_id: dataSummary?.collected_person_id,
				allocation_date_time: dataSummary?.allocation_date_time,
			};
			dispatch(getCashierStateTransition(stateData)).then((res) => {
				setCurrentStatus(res);
			});
		}
	}, [dispatch, dataSummary]);

	useEffect(() => {
		if (currentStatus) {
			let checkEdit = allVerified ? 'salesman_edits' : 'cashier_actions';
			let type = allVerified ? 'edit_type' : 'payment_type';
			const nonActionablePayment = links.filter(
				(item) => currentStatus?.[checkEdit]?.[item[type]]
			);
			const actionablePayment = links.filter(
				(item) => !currentStatus?.[checkEdit]?.[item[type]]
			);
			const newStepperData = [...nonActionablePayment, ...actionablePayment];
			setCurrentProxies(newStepperData.map((item) => item.id));
			setStepperData(newStepperData);
		}
	}, [currentStatus, allVerified]);

	useEffect(() => {
		if (currentStatus?.cashier_actions) {
			let cashierVerified = links.every(
				(item) => currentStatus?.cashier_actions?.[item?.payment_type]
			);
			let salesmanVerified = links.some(
				(item) => currentStatus?.salesman_edits?.[item?.edit_type]
			);
			setAllVerified(cashierVerified);
			setNoEdits(salesmanVerified);
		}
	}, [currentStatus]);

	useEffect(() => {
		if (currentProxies.length) {
			let type = allVerified ? 'edit_type' : 'payment_type';
			for (let i = 0; i < links.length; i++) {
				if (!currentStatus?.['cashier_actions']?.[links[i][type]]) {
					let step = currentProxies.indexOf(i);
					setCurrent(step);
					return;
				}
			}
			setCurrent(0);
		}
	}, [allVerified, currentStatus, currentProxies]);

	useEffect(() => {
		setCurrentProxy(currentProxies[current]);
	}, [current, currentProxies]);

	const handleStepClick = (index) => {
		setCurrentProxy(currentProxies[index]);
	};

	const handleSaveClick = () => {
		if (current < links.length - 1) {
			setCurrent(current + 1);
		} else {
			let pathname = '/cashier';
			navigate({
				pathname,
				search: urlData?.queryString,
			});
		}
	};

	return (
		<StyledDiv>
			<Steps
				style={{ width: '90%', margin: '10px auto 20px auto' }}
				current={current}
				progressDot={(dot, info) => {
					return customDot(dot, info, handleStepClick, noEdits, currentStatus);
				}}
			>
				{stepperData?.map((item, index) => (
					<Step
						key={item.id}
						title={item?.title}
						status={
							allVerified || index < current
								? 'finish'
								: current == index
								? 'process'
								: 'wait'
						}
					/>
				))}
			</Steps>
			{currentProxy === 0 && (
				<CashVerification handleSaveClick={handleSaveClick} stepsData={currentStatus} />
			)}
			{currentProxy === 1 && (
				<ChequeVerification handleSaveClick={handleSaveClick} stepsData={currentStatus} />
			)}
			{currentProxy === 2 && (
				<UpiVerification handleSaveClick={handleSaveClick} stepsData={currentStatus} />
			)}
			{currentProxy === 3 && (
				<NEFTVerification handleSaveClick={handleSaveClick} stepsData={currentStatus} />
			)}
		</StyledDiv>
	);
}

export default CashierStepperComponent;
CashierStepperComponent.propTypes = {
	dataSummary: PropTypes.object,
	current: PropTypes.number,
};
