import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Box } from 'atoms';

export default function RefreshIcon() {
	return (
		<Box
			style={{
				background: '#0094FF',
				width: '18px',
				height: '18px',
				position: 'absolute',
				right: '-2px',
				top: '-5px',
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: '1px solid white',
			}}
		>
			<SyncOutlined style={{ color: 'white', fontSize: '10px' }} />
		</Box>
	);
}
