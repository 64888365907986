import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';

const initialState = {
	salesOrderList: [],
};

const ORDERADAPTER_URL = process.env.REACT_APP_API_WMS_GRN_OA_URL;
const OMS_URL = process.env.REACT_APP_OMS;
const RETURN_API = process.env.REACT_APP_API_RETURNS_API;
const RETAILER_API = process.env.REACT_APP_API_RETAILER_API;

export const salesOrderSlice = createSlice({
	name: 'salesOrder',
	initialState,
	reducers: {
		setSalesOrderList: (state, { payload }) => {
			state.salesOrderList = { ...payload };
		},
	},
});

export const getSalesOrderById =
	({ id }) =>
	async () => {
		const CREDENTIALS = {
			url: `champ/order/${id}`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getSalesOrderAllocation =
	({ id }) =>
	async () => {
		const CREDENTIALS = {
			url: `champ/order/allocation/${id}`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getStockOneCallBack = (record) => async () => {
	const CREDENTIALS = {
		url: `${ORDERADAPTER_URL}wms-view/`,
		method: 'get',
		queryParams: {
			fetch_status: true,
			order_id: record?.id,
		},
	};

	return API.common(CREDENTIALS).then((response) => response);
};

export const getStockFetchStatusList =
	(record = []) =>
	async () => {
		const CREDENTIALS = {
			url: `${ORDERADAPTER_URL}wms-view/`,
			method: 'get',
			queryParams: {
				fetch_status_list: true,
				order_id_list: record?.toString(),
			},
		};

		return API.common(CREDENTIALS).then((response) => response);
	};

export const getSalesOrderList =
	({
		offset,
		limit,
		query,
		sort_column = 'id',
		sort_direction = 'DESC',
		currentPage,
		brands,
		queryInvoice,
		fcs,
		bnpl_enabled,
		stores,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/list',
			data: {
				filter: {
					...rest,
					user_fcs_only: true,
					user_brands_only: true,
					q: query ? `*${query || ''}*` : undefined,
					qinvoice: queryInvoice ? `*${queryInvoice || ''}*` : undefined,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
					stores: stores?.length ? stores : undefined,
				},
				page: { offset, limit },
				sort: { sort_column, sort_direction },
			},
		};

		if (bnpl_enabled === true) {
			CREDENTIALS.data.filter.bnpl_enabled = 1;
		}
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getSoTaggingList =
	({
		offset,
		limit,
		query,
		sort_column = 'id',
		sort_direction = 'DESC',
		currentPage,
		brands,
		queryInvoice,
		fcs,
		stores,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/list',
			data: {
				filter: {
					...rest,
					statuses: ['OP', 'OC', 'PP', 'PU'],
					user_fcs_only: true,
					user_brands_only: true,
					q: query ? `*${query || ''}*` : undefined,
					qinvoice: queryInvoice ? `*${queryInvoice || ''}*` : undefined,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
					stores: stores?.length ? stores : undefined,
				},
				page: { offset, limit },
				sort: { sort_column, sort_direction },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const postMarkOrders = (actionType, orderList) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_URL}api/update_eco_cod_salesorder`,
		data: {
			action: actionType,
			order_id: orderList,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const retailerKYC = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${RETAILER_API}cdms/retailer/kyc`,
		data: {
			order_id: id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const retailerGeofenceVerify = (verifyType, keyId) => async () => {
	// console.log('verifyType ', verifyType);
	// console.log('keyId', keyId);

	const CREDENTIALS = {
		method: 'post',
		url: `${RETAILER_API}cdms/retailer/geofence/verify`,
		data: {
			approval_status: verifyType,
			kyc_id: keyId,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const getSaleStoreList =
	({ q = '**', fc_ids = [] }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/store/list',
			data: {
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					q: q || '**',
					fields: ['name', 'code'],
					fc_ids: fc_ids?.length ? fc_ids : undefined,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
				page: {
					limit: 25,
					offset: 0,
				},
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getSalesReturnList = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${RETURN_API}returns-history`,
		data: {
			order_id: id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const unBlockOrders = (res) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_URL}api/unblock_orders`,
		data: { data: res },
	};
	return API.common(CREDENTIALS).then((response) => response);
};
export const fetchReasonsApiCall = () => async () => {
	const CREDENTIALS = {
		method: 'get',
		url: `${OMS_URL}api/unblock_reasons`,
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const getCollectionData = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_URL}collections/collections-history`,
		data: {
			order_id: id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const getOrderJourneyData = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_URL}api/order/journey`,
		data: {
			order_id: id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getSalesReturnInfo = (return_id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${RETURN_API}returns-history-info`,
		data: {
			return_id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const { setSalesOrderList } = salesOrderSlice.actions;

export default salesOrderSlice.reducer;
