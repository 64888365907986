/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RemarksIcon } from 'components/pages/segregator';
import { Rupee } from 'components';
import { getChequesUPIList, saveChequesOrUPIList } from 'store/invoiceSlice';
import { Flex, Button, Text } from 'atoms';
import { useURL } from 'hooks';
import { getunique, unScReplace } from 'utils/text';
import { getGreenRedTicksV2, getTransactionsV2 } from 'utils/helper';
import ShowSuccessModal from 'components/modals/ShowSuccessModal';
import CashierVerificationFlow from './CashierVerificationFlow';

export default function ChequeList({ handleSaveClick, stepsData }) {
	const dispatch = useDispatch();
	const [invoices, setInvoices] = useState([]);
	const [isValid, setIsValid] = useState(false);
	const urlData = useURL();
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	useEffect(() => {
		setIsValid(
			invoices?.every((item) => item?.verification_checked || item?.rejection_checked)
		);
	}, [invoices, cashierPersonHeaderDetails]);

	useEffect(() => {
		const data = {
			...urlData,
			payment_type: 'Cheque',
		};

		dispatch(getChequesUPIList(null, data)).then((res) => {
			res?.data?.forEach((element) => {
				element.disabled = element?.payment_verification_status === 'VerifiedByCashier';
				element.verification_checked =
					element?.payment_verification_status === 'VerifiedByCashier';
				element.rejection_checked =
					element?.payment_verification_status === 'RejectedByCashier';
			});
			setInvoices(res?.data);
		});
	}, [dispatch, urlData]);

	function handleVerifyReject(
		record,
		type = 'verify',
		rejectedReason = null,
		rejected_Reason = null
	) {
		const records = invoices.map((item) => {
			if (item?.key === record?.key) {
				return getGreenRedTicksV2(type, item, rejectedReason, rejected_Reason);
			}
			return item;
		});
		setInvoices([...records]);
	}

	const columns = [
		{
			title: 'Invoice Numbers',
			dataIndex: 'invoice_nos',
			key: 'invoice_nos',
			render: (invoice_nos) => invoice_nos.split(',').map((si) => <Text key={si}>{si}</Text>),
		},
		{
			title: 'Store Name',
			dataIndex: 'storeName',
			key: 'storeName',
			filters: getunique(invoices, 'storeName'),
			onFilter: (value, record) => record.storeName.startsWith(value),
			filterSearch: true,
			render: (storeName) => <span>{unScReplace(storeName)}</span>,
		},
		{
			title: 'Cheque no.',
			dataIndex: 'referenceNumber',
			key: 'referenceNumber',
			render: (record) => <div>{record}</div>,
		},
		{
			title: 'Bank',
			dataIndex: 'bankName',
			key: 'bankName',
			filters: getunique(invoices, 'bankName'),
			onFilter: (value, record) => record.bankName.startsWith(value),
			filterSearch: true,
			render: (bankName) => <span>{unScReplace(bankName)}</span>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (record) => <Rupee>{Number(record)}</Rupee>,
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			render: (dueDate) => <>{moment(moment(dueDate)).format('DD-MMM-YYYY')}</>,
		},
		{
			title: 'Verification',
			dataIndex: 'verified_by_segregator_status',
			key: 'verified_by_segregator_status',
			render: (_, record) => (
				<CashierVerificationFlow
					record={{
						...record,
						collected_person_id:
							cashierPersonHeaderDetails?.data_summary?.collected_person_id,
						brand: cashierPersonHeaderDetails?.data_summary?.brand_name,
					}}
					handleVerifyReject={handleVerifyReject}
					showRejection
					remarksType="CHEQUE_PAYMENT_REJECT"
					disabled={record?.disabled || false}
				/>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'salesman_name',
			key: 'salesman_name',
			render: (_, record) => <RemarksIcon record={record} type="CHEQUE_PAYMENT" />,
		},
	];

	function handleChequeVerification() {
		dispatch(
			saveChequesOrUPIList({
				payment_type: 'Cheque',
				collected_person_id: cashierPersonHeaderDetails?.data_summary?.collected_person_id,
				allocation_date_time:
					cashierPersonHeaderDetails?.data_summary?.allocation_date_time,
				cid: cashierPersonHeaderDetails?.data_summary?.cid,
				transactions: getTransactionsV2(invoices, urlData, 'CHEQUE_PAYMENT_REJECT'),
			})
		).then(() => {
			setIsSuccessModalOpen(true);
			handleSaveClick();
		});
	}

	return (
		<div>
			<Table
				rowKey={(record) => `${record?.key}`}
				dataSource={invoices}
				columns={columns}
				pagination={false}
			/>
			<Flex justifyContent="flex-end" mt="2rem">
				<Button
					onClick={handleChequeVerification}
					disabled={
						invoices?.length === 0 ||
						!isValid ||
						stepsData?.cashier_actions?.cheque_action
					}
				>
					{stepsData?.cashier_actions?.cheque_action ? 'Verified' : 'Save'}
				</Button>
				<ShowSuccessModal
					isSuccessModalOpen={isSuccessModalOpen}
					setIsSuccessModalOpen={setIsSuccessModalOpen}
					type="Cheque"
				/>
			</Flex>
		</div>
	);
}

ChequeList.propTypes = {
	handleSaveClick: PropTypes.func,
	stepsData: PropTypes.any,
};
