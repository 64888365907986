import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RemarksIcon } from 'components/pages/segregator';
import { Rupee } from 'components';
import { getChequesUPIList, manualNeftVerification } from 'store/invoiceSlice';
import { Flex, Button, Text, Image, Box } from 'atoms';
import { useURL } from 'hooks';
import { getunique, unScReplace } from 'utils/text';
import { theme } from 'styles/theme';
import greenTickFill from 'assets/svg/green-tick-fill.svg';
import redCloseFill from 'assets/svg/red-close-fill.svg';

const ButtonContainer = styled(Flex)`
	justify-content: center;
	margin-top: 2rem;
`;

const WarningText = styled(Text)`
	font-weight: 600px !important;
	font-size: 16px;
`;

const Verification = styled(Box)`
	margin-left: 20px;
`;

export default function NEFTList() {
	const dispatch = useDispatch();
	const [invoices, setInvoices] = useState({});
	const [showPopup, setShowPopup] = useState();
	const [rowSelect, setrowSelect] = useState([]);
	const urlData = useURL();
	const navigate = useNavigate();
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);
	// const disabledVerificationErrors = [
	// 	'This reference number is already verified, reach out to support team',
	// 	'This reference number belongs to UPI, reach out to support team',
	// ];

	useEffect(() => {
		neftListing();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, urlData, cashierPersonHeaderDetails.payment_verification_status]);

	function neftListing() {
		const data = {
			...urlData,
			payment_type: 'NEFT',
		};
		console.log(cashierPersonHeaderDetails?.payment_verification_status);

		dispatch(getChequesUPIList(null, data)).then((res) => {
			res?.data?.forEach((element) => {
				element.disabled = element?.payment_verification_status === 'VerifiedByCashier';
				element.verification_checked =
					element?.payment_verification_status === 'VerifiedByCashier';
				element.rejection_checked =
					element?.payment_verification_status === 'RejectedByCashier';
			});
			setrowSelect([]);
			setInvoices(res);
		});
	}
	const rowSelection = {
		onChange: (selectedRowKeys) => {
			console.log(selectedRowKeys);
			setrowSelect([...selectedRowKeys]);
		},
		getCheckboxProps: (record) => ({
			disabled:
				record.is_ready_neft_verify === 0 ||
				record.popup_neft_flag === 1 ||
				record.errorMessage ===
					'This reference number is already verified, reach out to support team' ||
				record.errorMessage ===
					'This reference number belongs to UPI, reach out to support team' ||
				record.payment_verification_status !== '',
		}),
	};
	const columns = [
		{
			title: 'Invoice Number',
			dataIndex: 'invoice_nos',
			key: 'invoice_nos',
			render: (invoice_nos) => invoice_nos.split(',').map((si) => <Text key={si}>{si}</Text>),
		},
		{
			title: 'Store Name',
			dataIndex: 'storeName',
			key: 'storeName',
			filters: getunique(invoices?.data, 'storeName'),
			onFilter: (value, record) => record.storeName.startsWith(value),
			filterSearch: true,
			render: (storeName) => <span>{unScReplace(storeName)}</span>,
		},
		{
			title: 'Reference Number',
			dataIndex: 'referenceNumber',
			key: 'referenceNumber',
			render: (record) => <div>{record}</div>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (record) => <Rupee>{Number(record)}</Rupee>,
		},
		{
			title: <Text textAlign="center">Verification</Text>,
			dataIndex: 'errorMessage',
			key: 'errorMessage',
			render: (errorMessage, record) => (
				<Flex justifyContent="center" fontSize="15px" style={{ color: theme.colors.error }}>
					{errorMessage !== '' && (
						<Tooltip title={errorMessage}>
							<WarningOutlined />
						</Tooltip>
					)}
					<Verification>
						{record?.payment_verification_status === 'VerifiedByCashier' && (
							<Image width={24} height={24} src={greenTickFill} alt="greenTick" />
						)}
						{record?.payment_verification_status === 'RejectedByCashier' && (
							<Image width={24} height={24} src={redCloseFill} alt="redTick" />
						)}
					</Verification>
				</Flex>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'salesman_name',
			key: 'salesman_name',
			render: (_, record) => <RemarksIcon record={record} type="NEFT_PAYMENT" />,
		},
	];

	function onClickVerify() {
		setShowPopup(true);
	}

	function onClickCancelPopup() {
		setShowPopup(false);
	}

	function isRedirectible() {
		// const remainingInvoices = invoices.data.filter((p) =>
		// 	paymentIds.find((x) => x === p.payment_ids)
		// );

		// const errorBlockedInvoices = remainingInvoices.filter((p) =>
		// 	disabledVerificationErrors.find((x) => x === p.errorMessage)
		// ).length;
		const pendingVerification = invoices.data.filter(
			(p) => p.payment_verification_status !== ''
		).length;

		const redirect = invoices.data.length === rowSelect.length + pendingVerification;
		console.log(redirect);
		return redirect;
	}
	function verifyAndRedirect() {
		const selected = invoices.data.filter((i) => rowSelect.find((x) => x === `${i.key}`));
		const paymentIds = selected.map((p) => p.payment_ids);
		const shouldRedirect = isRedirectible();
		console.log(cashierPersonHeaderDetails);

		dispatch(
			manualNeftVerification(
				cashierPersonHeaderDetails?.data_summary?.allocation_date_time,
				cashierPersonHeaderDetails?.data_summary?.collected_person_id,
				cashierPersonHeaderDetails?.data_summary?.cid,
				paymentIds
			)
		).then(() => {
			if (shouldRedirect) {
				let pathname = `/cashier`;
				if (
					cashierPersonHeaderDetails?.data_summary?.total_cash > 0 &&
					!cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.Cash
				) {
					pathname = `/cashier/cash-verify`;
				} else if (
					cashierPersonHeaderDetails?.data_summary?.total_cheque > 0 &&
					!cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.Cheque
				) {
					pathname = `/cashier/cheque-verify`;
				} else if (
					cashierPersonHeaderDetails?.data_summary?.total_upi > 0 &&
					!cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.UPI
				) {
					pathname = `/cashier/upi-verify`;
				}
				navigate({
					pathname,
					search: urlData?.queryString,
				});
			} else {
				setShowPopup(false);
				neftListing();
			}
		});
	}

	return (
		<div>
			<Table
				rowKey={(record) => `${record?.key}`}
				dataSource={invoices?.data}
				columns={columns}
				pagination={false}
				rowSelection={{
					type: 'checkbox',
					preserveSelectedRowKeys: true,
					selectedRowKeys: rowSelect,
					hideSelectAll: true,
					...rowSelection,
				}}
			/>

			<Flex justifyContent="flex-end" mt="2rem">
				{invoices.popup_neft_flag === 0 && (
					<Button
						onClick={verifyAndRedirect}
						disabled={invoices?.data?.length === 0 || rowSelect.length === 0}
					>
						Verify
					</Button>
				)}

				{invoices.popup_neft_flag === 1 && (
					<Button
						onClick={onClickVerify}
						disabled={invoices?.data?.length === 0 || rowSelect.length === 0}
					>
						Verify
					</Button>
				)}
				<Modal
					centered
					visible={showPopup}
					footer={null}
					onCancel={onClickCancelPopup}
					maskClosable={false}
				>
					<Box mt="3" textAlign="center">
						<WarningText>
							You are verifying the transactions which are not present in the bank.
							This instance will be reported
						</WarningText>
					</Box>
					<ButtonContainer>
						<Button onClick={verifyAndRedirect}>Proceed</Button>
						<Button onClick={onClickCancelPopup}>Cancel</Button>
					</ButtonContainer>
				</Modal>
			</Flex>
		</div>
	);
	// verification
}
