import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { API } from 'api';

export const TODAY = moment(moment()).format('DD-MMM-YYYY');
const initialState = { tab: '1', date: TODAY };

export const cashierSlice = createSlice({
	name: 'cashier',
	initialState,
	reducers: {
		setCollectionList: (state, { payload }) => {
			const { count = 0, data = [], data_summary = {} } = payload;
			state.collectionList = {
				count,
				data: [...data],
				data_summary,
			};
		},
	},
});

const URL = process.env.REACT_APP_COLLECTION_API_URL;
// const CashierURL = process.env.REACT_APP_CASHIER_API_URL;
const CashierRevampURLV1 = process.env.REACT_APP_CASHIER_API_URL_REVAMP_VERSION1;
const CashierRevampURLV2 = process.env.REACT_APP_CASHIER_API_URL_REVAMP_VERSION2;

// #region allocation slice starts here
export const getNextDayCollectionList =
	({ status, dayRange = [], searchText = undefined, salesman = undefined, isDownload }) =>
	async () => {
		const CREDENTIALS = {
			url: `${URL}invoice-handover/list`,
			method: 'post',
			queryParams: {
				limit: 100000,
				assigned_status: status,
				day_number: dayRange.join(',') || undefined,
				date_range_days: 7,
				salesman_id: salesman || undefined,
				invoice_no_query: searchText || undefined,
				orders_condition_sheet: isDownload ? 'send' : undefined,
			},
		};

		if (isDownload) {
			CREDENTIALS.config = {
				responseType: 'blob',
			};
		}

		return API.common(CREDENTIALS).then((response) => response);
	};

export const getSalesManList =
	({ q, fc_id = null, brand_id = null }) =>
	async (_, getState) => {
		const { auth } = getState();
		const CREDENTIALS = {
			url: `/champ/salesman/list`,
			method: 'post',
			data: {
				sort: {
					sort_column: 'createdAt',
					sort_direction: 'DESC',
				},
				page: {
					offset: 0,
					limit: 100,
				},
				filter: {
					...(q ? { q } : {}),
					fc_id: fc_id || auth?.me?.FCs?.map((x) => x?.id),
					brand_id: brand_id || auth?.me?.Brands?.map((x) => x?.id),
					fields: ['name', 'mobile'],
					active_salesman_only: true,
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response?.data?.rows);
	};

export const assignSalesMan =
	({ data }) =>
	async () => {
		const CREDENTIALS = {
			url: `${URL}invoice-handover/update`,
			method: 'put',
			data,
		};

		return API.common(CREDENTIALS).then((response) => response?.data);
	};

// #endregion

// #region verification slice starts here

export const getCollectionList =
	({ q = '' }) =>
	async (dispatch, getState) => {
		/**
		 * To get salesmen/Delivery Persons list
		 */
		const { date, tab } = getState().cashier;
		const { version } = getState().auth.auth;
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}invoicecollectionlist`,
			queryParams: {
				search_info: q,
				search_date: date,
				tab,
			},
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setCollectionList(response));
			return response;
		});
	};

// #endregion
export const getcashierVerificationList =
	(collected_person_id, allocated_date) => async (dispatch, getState) => {
		/**
		 * To get salesmen/Delivery Persons list
		 */
		const { version } = getState().auth.auth;
		const { tab } = getState().invoice;
		const queryParams = {
			search_info: '',
			tab,
			offset: 0,
			limit: 50,
			collected_person_id,
			allocation_date_time: allocated_date,
			verified_by_segregator_status: 'Verified',
			verified_by_cashier_status: 'Ready for verification',
			return_status: 'null',
			payment_type: 'Cash',
		};
		if (version === 'v2' && tab === '1') {
			queryParams.rejected_invoices = false;
		}
		const CREDENTIALS = {
			url: `${
				version === 'v1' || tab === '2' ? CashierRevampURLV1 : CashierRevampURLV2
			}cashierVerificationList`,
			queryParams,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setCollectionList(response));
			return response;
		});
	};
export const { setCollectionList } = cashierSlice.actions;

export default cashierSlice.reducer;
