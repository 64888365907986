import { message } from 'antd';
import { isNaN } from 'lodash';
import moment from 'moment';

export function getBase64(file) {
	return new Promise((resolve) => {
		let baseURL = '';
		// Make new FileReader
		const reader = new FileReader();

		if (file && file.type.match(/image\/.*|application\/pdf/)) {
			reader.readAsDataURL(file);
		}
		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object
			baseURL = reader.result;
			resolve(baseURL);
		};
	});
}

export function getGreenRedTicks(type, item, rejectReason = null) {
	let greenTick = false;
	let redTick = false;

	if (type === 'verify' && !item?.verification_checked) {
		/**
		 * if verify button clicked and in previous its not verified, make that as verified
		 */
		greenTick = true;
		/**
		 * Green tick is enabled, uncheck the red check
		 */
		redTick = false;
	} else if (type === 'reject' && !item?.rejection_checked) {
		/**
		 * If reject is clicked, and its not enabled previously, enable red check.
		 * if red check is enabled uncheck the green check.
		 */
		greenTick = false;
		redTick = true;
	}

	return {
		...item,
		verification_checked: greenTick,
		rejection_checked: redTick,
		rejectReason,
	};
}
export function getGreenRedTicksV2(type, item, rejectReason = null, reject_reason = null) {
	let greenTick = false;
	let redTick = item?.rejection_checked ?? false;

	if (type === 'verify' && !item?.verification_checked) {
		/**
		 * if verify button clicked and in previous its not verified, make that as verified
		 */
		greenTick = true;
		/**
		 * Green tick is enabled, uncheck the red check
		 */
		redTick = false;
	} else if (type === 'reject' && !item?.rejection_checked) {
		/**
		 * If reject is clicked, and its not enabled previously, enable red check.
		 * if red check is enabled uncheck the green check.
		 */
		greenTick = false;
		redTick = true;
	}

	return {
		...item,
		verification_checked: greenTick,
		rejection_checked: redTick,
		rejectReason,
		reject_reason,
	};
}

export function maxFileSize(file) {
	if (!file) {
		return false;
	}
	const MAX_SIZE = 25;
	const isLt2M = file.size / 1024 / 1024 < MAX_SIZE;
	if (!isLt2M) {
		file.flag = true;
		message.error(`File size must smaller than ${MAX_SIZE} MB!`);
		return false;
	}
	return true;
}
function getPaymemtCheck(item) {
	console.log(item);
	if (item?.payment_verification_status === 'UpiAutoVerified') {
		return false;
	}
	if (item.verification_checked === false && item.rejection_checked === false) {
		return false;
	}
	if (item.verification_checked === true && item.rejection_checked === false) {
		return true;
	}
	// eslint-disable-next-line sonarjs/prefer-single-boolean-return
	if (item.verification_checked === false && item.rejection_checked === true) {
		return true;
	}
	return false;
}
export function getTransactions(invoices = [], urlData = {}, rejectionType = null) {
	return invoices?.map((item) => ({
		payment_ids: item.payment_ids,
		invoice_ids: item.invoice_ids,
		// payment_checked: item?.verification_checked || item?.rejection_checked,
		payment_checked: getPaymemtCheck(item),
		verification_checked: item.verification_checked,
		rejection_checked: item.rejection_checked,
		store_id: item?.store_id,
		reference_number: item.referenceNumber,
		...(item?.rejectReason && {
			reject_remark: {
				remark_status: rejectionType,
				brand_id: urlData?.brand_id,
				remark: item?.rejectReason,
				unique_key_code: '',
			},
		}),
	}));
}
export function getTransactionsV2(invoices = [], urlData = {}, rejectionType = null) {
	return invoices?.map((item) => ({
		payment_ids: item.payment_ids,
		invoice_ids: item.invoice_ids,
		// payment_checked: item?.verification_checked || item?.rejection_checked,
		payment_checked: getPaymemtCheck(item),
		verification_checked: item.verification_checked,
		rejection_checked: item.rejection_checked,
		store_id: item?.store_id,
		reference_number: item.referenceNumber,
		...(item?.reject_reason && {
			reject_remark: {
				remark_status: rejectionType,
				brand_id: urlData?.brand_id,
				remark: item?.rejectReason,
				unique_key_code: '',
				reject_reason: item?.reject_reason,
			},
		}),
	}));
}

export function getUrlExtension(url) {
	if (url) {
		return url.split(/[#?]/)[0].split('.').pop().trim();
	}
	return false;
}

export function getOnlyDate(verified_by_cashier_at, record) {
	if (verified_by_cashier_at) {
		return moment(verified_by_cashier_at).format('DD-MM-YYYY');
	}
	if (record?.verified_by_segregator_at) {
		return moment(record?.verified_by_segregator_at).format('DD-MM-YYYY');
	}
	return '-';
}
// eslint-disable-next-line consistent-return
const getDataCheck = (val) => {
	console.log(val);
	// eslint-disable-next-line use-isnan
	if (val === undefined || isNaN(val) || val === NaN || val === 0) {
		return 0;
	}
	if (val > 0) {
		return val;
	}
};
export const getTotalAmount = (values) => {
	console.log(values);
	let cashValue = 0;
	let upiValue = 0;
	let chequeValue = 0;
	let neftValue = 0;

	cashValue = getDataCheck(values?.cash);
	upiValue = Number(getDataCheck(values?.upi.reduce((n, { amount }) => n + amount, 0)));
	chequeValue = Number(getDataCheck(values?.cheque.reduce((n, { amount }) => n + amount, 0)));
	neftValue = Number(getDataCheck(values?.neft.reduce((n, { amount }) => n + amount, 0)));

	console.log(upiValue, chequeValue, neftValue, cashValue);
	console.log(upiValue + chequeValue + neftValue + cashValue);

	return upiValue + chequeValue + neftValue + cashValue;
};
