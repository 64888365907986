import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Image, Flex, Text } from 'atoms';
import { Line } from 'components';
import pending from 'assets/svg/pending.svg';
import totalBillBack from 'assets/svg/total-bill-back.svg';
import verified from 'assets/svg/verified.svg';
import readyForVerification from 'assets/svg/ready-for-verification.svg';
import partiallyVerified from 'assets/svg/partially-verified.svg';

export default function Stats() {
	const { collectionList = {}, tab } = useSelector((state) => state.invoice);
	const { version } = useSelector((state) => state.auth.auth);

	return (
		<Container>
			<Card>
				<Image width={36} height={36} src={totalBillBack} alt="totalBillBack" />
				<Flex flexDirection="column" pl="0.25rem" alignItems="center">
					<Title maxWidth="80px" textAlign="center">
						Total Invoice Returned
					</Title>
					<Count>{collectionList?.data_summary?.total_bill_back || 0}</Count>
				</Flex>
			</Card>
			<Card>
				<Count>+</Count>
			</Card>
			<Card>
				<Flex flexDirection="column" alignItems="center">
					<Title minHeight="30px">Voucher</Title>
					<Count>{collectionList?.data_summary?.total_voucher || 0}</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" alignItems="center">
					<Title maxWidth="50px" textAlign="center">
						Missing Invoices
					</Title>
					<Count>{collectionList?.data_summary?.total_no_of_missing_invoice || 0}</Count>
				</Flex>
			</Card>
			<Line height="158px" mx="0.3rem" />
			<Card>
				<Image width={36} height={36} src={verified} alt="verified" mr="0.5rem" />
				<Flex flexDirection="column" pl="0.25rem">
					<Title>Verified</Title>
					<Count>
						{collectionList?.data_summary?.total_rows_verified_by_segregator || 0}
					</Count>
				</Flex>
			</Card>
			<Card>
				<Image width={36} height={36} src={pending} alt="pending" mr="0.5rem" />
				<Flex flexDirection="column" pl="0.25rem">
					<Title>Pending</Title>
					<Count>
						{collectionList?.data_summary?.total_rows_pending_by_segregator || 0}
					</Count>
				</Flex>
			</Card>
			<Card>
				<Image
					width={36}
					height={36}
					src={readyForVerification}
					alt="verified"
					mr="0.5rem"
				/>
				<Flex flexDirection="column" pl="0.25rem">
					<Title maxWidth="55px">Ready for verification</Title>
					<Count>
						{collectionList?.data_summary
							?.total_rows_ready_for_verification_by_segregator || 0}
					</Count>
				</Flex>
			</Card>
			<Card>
				<Image width={36} height={36} src={partiallyVerified} alt="pending" mr="0.5rem" />
				<Flex flexDirection="column" pl="0.25rem">
					<Title maxWidth="55px">Partially Verified</Title>
					<Count>
						{collectionList?.data_summary?.total_rows_partial_verified_by_segregator ||
							0}
					</Count>
				</Flex>
			</Card>
			{tab === '1' && version === 'v2' && (
				<Card>
					<Image
						width={36}
						height={36}
						src={partiallyVerified}
						alt="pending"
						mr="0.5rem"
					/>
					<Flex flexDirection="column" pl="0.25rem">
						<Title maxWidth="55px">Rejected by Cashier</Title>
						<Count>
							{collectionList?.data_summary?.total_count_rejected_by_cashier || 0}
						</Count>
					</Flex>
				</Card>
			)}
		</Container>
	);
}

export const Container = styled(Flex)`
	padding: 5px;
	border: 1px solid ${(props) => props.theme.colors.Isabelline};
	border-radius: 7px 7px 10px 10px;
	margin-bottom: 1rem;
	background-color: ${(props) => props.theme.colors.white};
`;

export const Card = styled(Flex)`
	margin: 10px;
	align-items: center;
`;

export const Title = styled(Text)`
	font-weight: 300;
	font-size: 12px;
	padding-bottom: 0.5rem;
`;

export const Count = styled.div`
	font-size: 18px;
`;
