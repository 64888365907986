/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Upload, message, Space, Button, Modal } from 'antd';
import { config, S3, CognitoIdentityCredentials } from 'aws-sdk';
import { Select as FormikSelect } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
// eslint-disable-next-line import/no-unresolved, import/extensions
import {
	getMetafilePath,
	getHULMultiFileObject,
	getITCFieObject,
	getBRITFieObject,
	getNvaDbrFileObject,
	getNeslFileObject,
	getGDJFileObject,
	AdpterFormSchema,
	formInitialValues,
	OrderAdapterUploadList,
	SalesReturnUploadList,
	getHULSalesReturnFileObject,
} from 'pages/uploads/uploadsController';
import { FormInput, Flex, Box, Text, AsyncSelect } from 'atoms';
import { toAsysnOrderAdapterOption, SelectFilterLabelOptions } from 'utils';
import { getFcOnSearchList } from 'store/masters';
import { hideLoader, showLoader } from 'store/appSlice';
import { StyledSubmitButton } from 'components/forms/masters/helper';
import { Debug } from 'components/forms/Debug';
// import { ErrorText } from 'components/common/ErrorText';
import { theme } from 'styles/theme';
import { BRAND_CHOICE } from './onboarding/helper';

const BUCKET_NAME = process.env?.REACT_APP_BUCKET_NAME;
const BUCKET_REGION = process.env?.REACT_APP_BUCKET_REGION;
const IDENTITYPOOL_ID = process.env?.REACT_APP_S3_IDENTITYPOOL_ID;

export const BtnWrap = styled(Flex)`
	justify-content: flex-end;
`;

export const multiFileWrap = styled(Flex)`
	align-items: center;
	justify-content: space-around;
	width: 100vw;
`;
export const UploadWrap = styled(Upload)`
	display: flex;
	width: 100%;
	background: aliceblue;
	min-height: 70px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const ErrorText = styled(Text)`
	mt: '0.5rem';
	textalign: 'center';
	color: ${theme.colors.error};
`;

const findDuplicates = (arr) => arr.length !== new Set(arr).size;

const { confirm } = Modal;

export default function UploadPageFormNew({ toggleState, handleTogglePaginate, toggleuploadType }) {
	const dispatch = useDispatch();
	const uploadType = toggleuploadType[0];
	const [fcList, setFcList] = useState({});
	const [, setModalVisible] = toggleState;
	const { auth = {} } = useSelector((state) => state.auth);
	const isAdmin = auth?.user?.is_superuser || false;
	const { me } = useSelector((state) => state.auth);
	const [uploadOrderAdapter, setUploadOrderAdapter] = useState(true);
	const [initialValues, setInitialValues] = useState(
		formInitialValues(uploadType, auth, isAdmin, me)
	);
	const [path, setPath] = useState('');

	useEffect(() => {
		setInitialValues(formInitialValues(uploadType, auth, isAdmin, me));
		setPathFunc(uploadType);
	}, [uploadType, auth, isAdmin, me]);

	useEffect(() => {
		setUploadOrderAdapter(true);
		dispatch(getFcOnSearchList({})).then((res) => {
			setFcList(res);
		});
	}, [dispatch]);

	// const handleuploadOrder = ({ file }, name, setFieldValue) => {
	// 	if (file.type === 'text/csv' && name && file.status !== 'removed') {
	// 		setFieldValue(`${name}`, [file]);
	// 	} else {
	// 		if (file.status !== 'removed') message.error(`Please upload only .CSV Format`);
	// 		setFieldValue(`${name}`, undefined);
	// 	}
	// };

	const handleuploadOrder = ({ file }, name, setFieldValue) => {
		if (
			(uploadType === 'picklite_old_invoice' &&
				file.type ===
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
			(uploadType !== 'picklite_old_invoice' && file.type === 'text/csv')
		) {
			if (name && file.status !== 'removed') {
				setFieldValue(`${name}`, [file]);
			}
		} else {
			if (file.status !== 'removed') {
				message.error(
					`Please upload only ${
						uploadType === 'picklite_old_invoice' ? '.XLSX' : '.CSV'
					} Format`
				);
			}
			setFieldValue(`${name}`, undefined);
		}
	};

	const setPathFunc = (type) => {
		if (type === 'picklite_order_adapter') {
			setPath('salesorder');
		}
		if (type === 'picklite_grn') {
			setPath('grn');
		}
		if (type === 'pack_master') {
			setPath('packmaster');
		}
		if (type === 'sales_return') {
			setPath('sales_return');
		}
		if (type === 'picklite_old_invoice') {
			setPath('picklite_old_invoice');
		}
	};

	// on Select Event
	const handleSelect = (name, value, setFieldValue) => {
		const getValue = isEmpty(value) ? '' : value?.value;
		return setFieldValue(name, getValue);
	};

	const afterUpload = () => {
		setModalVisible(false);
		handleTogglePaginate();
		setUploadOrderAdapter(true);
		dispatch(hideLoader());
	};

	const intiateFileUpload = (
		fileList,
		fc_value,
		brand_value,
		values,
		fileMulti,
		{ resetForm, setSubmitting }
	) => {
		const Uploadedfiles = [];
		let flagtoCheckUnique = false;
		const nanoId = nanoid();
		fileList.forEach(async (file) => {
			if (!isEmpty(file)) {
				await file?.reduce(
					(accPromise, nextfile) =>
						accPromise.then(() => {
							const metafilePath = getMetafilePath(nextfile, file, values, path);
							config.update({
								region: BUCKET_REGION,
								credentials: new CognitoIdentityCredentials({
									IdentityPoolId: IDENTITYPOOL_ID,
								}),
							});
							console.log('inside path', path);
							console.log('inside metafilepath', metafilePath);
							const pickliteObj = {
								brd_code: values?.brand,
								user_id: values?.user_id,
								file_type: 'pack_master',
								filename: metafilePath,
							};

							const obj = {
								...{ indb: '1' },

								hub_code: fc_value?.toString(),
								user_id: values.user_id,
								brd_code: brand_value,
								file_type: path,
								meta_file: metafilePath,
								file_ext: 'csv',
								multiplefile: JSON.stringify(fileMulti),
								filename: metafilePath,
								multifile_tag: nanoId,
							};

							new S3({
								apiVersion: '2006-03-01',
								params: { Bucket: BUCKET_NAME },
							}).upload(
								{
									Key: metafilePath,
									Body:
										values.file?.length > 1 ? nextfile.originFileObj : nextfile,
									ACL: 'public-read',
									Metadata:
										values?.upload_type === 'pack_master' ? pickliteObj : obj,
								},
								(err, data) => {
									if (err) {
										message.info(`${nextfile.name}: ${err}`);
										setUploadOrderAdapter(true);
									} else if (!flagtoCheckUnique) {
										Uploadedfiles.push(data.ETag);
										flagtoCheckUnique = findDuplicates(Uploadedfiles);
										if (flagtoCheckUnique) {
											confirm({
												title: 'Duplicates files',
												content: 'Same files not allowed to upload',
												// eslint-disable-next-line consistent-return
												async onOk() {
													try {
														setSubmitting(false);
														resetForm();
														afterUpload();
													} catch (e) {
														return console.log('Oops errors!');
													}
												},
												onCancel() {},
											});
										} else {
											message.info(`${nextfile.name}  Uploaded Successfully`);
											setSubmitting(false);
											resetForm();
											afterUpload();
										}
									}
								}
							);
						}),
					Promise.resolve()
				);
			}
		});
	};

	const handleSalesOrderSubmit = async (values, { resetForm, setSubmitting }) => {
		if (uploadOrderAdapter) {
			setUploadOrderAdapter(false);
			try {
				dispatch(showLoader());
				const { fc: fc_value, brand: brand_value } = values;

				let fileMulti = {};
				let fileList = [];

				console.log(values);

				if (values?.brand?.includes('HUL') && uploadType === 'picklite_order_adapter') {
					const { sales_register, shogun, bill } = values;
					fileList = [sales_register, shogun, bill];
					fileMulti = getHULMultiFileObject(
						fc_value,
						brand_value,
						sales_register,
						shogun,
						bill,
						path
					);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (values?.brand?.includes('ITC') && uploadType === 'picklite_order_adapter') {
					const { sales, master, credit_adj } = values;
					fileList = [sales, master, credit_adj];
					fileMulti = getITCFieObject(
						fc_value,
						brand_value,
						sales,
						master,
						credit_adj,
						path
					);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (values?.brand?.includes('BRIT') && uploadType === 'picklite_order_adapter') {
					const { so_report, inv_report, register } = values;
					fileList = [so_report, inv_report, register];
					fileMulti = getBRITFieObject(
						fc_value,
						brand_value,
						so_report,
						inv_report,
						register,
						path
					);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (
					(values?.brand?.includes('NVA') || values?.brand?.includes('DBR')) &&
					uploadType === 'picklite_order_adapter'
				) {
					const { so_report, inv_report } = values;
					fileList = [so_report, inv_report];
					fileMulti = getNvaDbrFileObject(
						fc_value,
						brand_value,
						so_report,
						inv_report,
						path
					);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (values?.brand?.includes('NESL') && uploadType === 'picklite_order_adapter') {
					const { sales, billwise, credit_adj } = values;
					fileList = [sales, billwise, credit_adj];
					fileMulti = getNeslFileObject(
						fc_value,
						brand_value,
						sales,
						billwise,
						credit_adj,
						path
					);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (values?.brand?.includes('GDJ') && uploadType === 'picklite_order_adapter') {
					const { sales, credit_adj } = values;
					fileList = [sales, credit_adj];
					fileMulti = getGDJFileObject(fc_value, brand_value, sales, credit_adj, path);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (
					(uploadType === 'picklite_order_adapter' &&
						!values?.brand?.includes('HUL') &&
						!values?.brand?.includes('BRIT') &&
						!values?.brand?.includes('NVA') &&
						!values?.brand?.includes('DBR') &&
						!values?.brand?.includes('ITC') &&
						!values?.brand?.includes('GDJ') &&
						!values?.brand?.includes('NESL')) ||
					uploadType === 'picklite_grn' ||
					uploadType === 'pack_master'
				) {
					// single file upload
					const { excel = [] } = values;
					console.log('values ', values);
					fileList = [excel];
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}

				if (uploadType === 'sales_return') {
					// single file for BRIT for now. Later multifile will come.
					const { excel = [] } = values;
					fileList = [excel];
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (uploadType === 'sales_return' && values?.brand?.includes('HUL')) {
					const { master, gstr } = values;
					fileList = [master, gstr];
					fileMulti = getHULSalesReturnFileObject(
						fc_value,
						brand_value,
						master,
						gstr,
						path
					);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (
					uploadType === 'sales_return' &&
					(values?.brand?.includes('NVA') || values?.brand?.includes('LOREAL'))
				) {
					const { sales_return_upload, sales_return_product } = values;
					fileList = [sales_return_upload, sales_return_product];
					fileMulti = getHULSalesReturnFileObject(
						fc_value,
						brand_value,
						sales_return_upload,
						sales_return_product,
						path
					);
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				if (uploadType === 'picklite_old_invoice') {
					const { excel = [] } = values;
					fileList = [excel];
					intiateFileUpload(fileList, fc_value, brand_value, values, fileMulti, {
						resetForm,
						setSubmitting,
					});
				}
				setSubmitting(false);
			} catch (e) {
				message.error(e?.response);
				setSubmitting(false);
			}
		}
	};

	const getListType = () => {
		let listType;
		if (uploadType === 'sales_return') {
			listType = SalesReturnUploadList;
		}
		if (uploadType === 'picklite_order_adapter') {
			listType = OrderAdapterUploadList;
		}
		return listType;
	};

	return (
		<Formik
			validationSchema={AdpterFormSchema}
			initialValues={initialValues}
			initialTouched={{
				pickers: true,
			}}
			onSubmit={handleSalesOrderSubmit}
			enableReinitialize
		>
			{({ values, setFieldValue, errors }) => {
				// const hasMultiple = values?.brand === 'HUL' || values?.brand === 'ITC';
				console.log({ errors });
				console.log({ values });
				console.log({ uploadType });

				return (
					<Form layout="vertical">
						{!isEmpty(uploadType) && (
							<Box>
								{uploadType !== 'pack_master' && (
									<Box marginBottom="1rem">
										<Text>Upload Parameter</Text>
										<Flex style={{ gap: '24px' }}>
											<FormInput name="fc" label="*FC(s)" style={{ flex: 1 }}>
												<>
													{!isEmpty(fcList) && (
														<AsyncSelect
															showSearch
															name="fc"
															transformOptions={(value) =>
																toAsysnOrderAdapterOption(value)
															}
															className="customDropdown"
															placeholder="Fc Type"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >=
																0
															}
															onChange={(value, options) => {
																handleSelect(
																	'fc',
																	value,
																	setFieldValue
																);
																setFieldValue('brand', undefined);
																setFieldValue(
																	'brandsOptions',
																	options?.filter(
																		(x) =>
																			x.value === value.value
																	)[0]?.brands || []
																);
															}}
															fetchOptions={getFcOnSearchList}
															selectOptions={toAsysnOrderAdapterOption(
																fcList
															)}
															defaultValue={
																isAdmin
																	? undefined
																	: auth?.user?.FCs?.[0]?.code
															}
														/>
													)}
													{/* {uploadType !== 'picklite_order_adapter' &&
													!isEmpty(fcList) && (
														<AsyncSelect
															showSearch
															name="fc"
															className="customDropdown"
															placeholder="Fc Type"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >=
																0
															}
															onChange={(value, options) => {
																handleSelect(
																	'fc',
																	value,
																	setFieldValue
																);
																setFieldValue('brand', undefined);
																setFieldValue(
																	'brandsOptions',
																	options?.filter(
																		(x) =>
																			x.value === value.value
																	)[0]?.brands || []
																);
															}}
															fetchOptions={getFcOnSearchList}
															selectOptions={toAsysnOption(fcList)}
															defaultValue={
																isAdmin
																	? undefined
																	: auth?.user?.FCs?.[0]?.id
															}
														/>
													)} */}
												</>
											</FormInput>

											<FormInput
												name="brand"
												label="*Brand"
												style={{ flex: 1 }}
											>
												<FormikSelect
													showSearch
													className="customDropdown"
													name="brand"
													placeholder="Select Brand"
													filterOption={SelectFilterLabelOptions}
													onChange={() => {
														setUploadOrderAdapter(true);
														console.log('came inside console');
													}}
													options={
														isAdmin
															? values?.brandsOptions || []
															: auth?.user?.Brands?.map((x) => ({
																	label: `${x?.code || ''}: ${
																		x?.name || ''
																	}`,
																	value: x?.code,
															  })) || []
													}
												/>
											</FormInput>
										</Flex>
									</Box>
								)}
								{uploadType === 'pack_master' && (
									<Box marginBottom="1rem">
										<Text>Upload Parameter</Text>
										<Flex
											style={{
												gap: '24px',
												width: '350px',
											}}
										>
											{/* <FormInput
												name="brand"
												label="*Brand"
												style={{ flex: 1 }}
											>
												<FormikSelect
													showSearch
													className="customDropdown"
													name="brand"
													placeholder="Select Brand"
													filterOption={SelectFilterLabelOptions}
													onChange={() => {
														setUploadOrderAdapter(true);
														console.log('came inside console 1');
													}}
													options={
														auth?.user?.Brands?.map((x) => ({
															label: `${x?.code || ''}: ${
																x?.name || ''
															}`,
															value: x?.code,
														})) || []
													}
												/>
											</FormInput> */}
											<FormInput name="brand" label="*Brand">
												<FormikSelect
													name="brand"
													// allowClear
													// showSearch
													// disabled={!isEditPage}
													filterOption={SelectFilterLabelOptions}
													options={BRAND_CHOICE}
													placeholder="Select Brand"
												/>
											</FormInput>
										</Flex>
									</Box>
								)}
								{values?.brand && (
									<>
										{/* <Flex alignItems="center" style={{ gap: '24px' }}>
										<FormInput name="non_picking" style={{ flex: 1 }}>
											<Text>Non-Pickers</Text>
											<Switch name="non_picking" />
										</FormInput>

										{!values.non_picking && !isEmpty(pickersList) && (
											<FormInput
												name="pickers"
												label="*Picker(s)"
												style={{ flex: 1 }}
											>
												<AsyncSelect
													showSearch
													mode="multiple"
													name="pickers"
													className="customDropdown"
													placeholder="Picker(s)"
													filterOption={(input, option) =>
														option.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}
													onChange={(value) =>
														handleMultipleSelect(
															'pickers',
															value,
															setFieldValue
														)
													}
													disabled={values.non_picking}
													fetchOptions={getPickersOnSearchList}
													allowClear
													selectOptions={pickersList}
												/>
											</FormInput>
										)}

										<FormInput
											name="pickers"
											label="*Pickers"
											hidden={!values?.non_picking}
										>
											<Input name="pickers" />
										</FormInput>
									</Flex> */}

										{/* {errors?.pickers && (
										<Text color="red" mb="1rem">
											{errors.pickers}
										</Text>
									)} */}

										<Text marginBottom="1rem">Upload Section</Text>
										<Flex>
											{/* ITC has 2 uploads therefore seperate code */}
											{/* Upload for ITC */}
											{values?.brand?.includes('ITC') &&
												uploadType === 'picklite_order_adapter' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{/* <Text color="red">
															This brand is not yet supported
														</Text> */}
														{OrderAdapterUploadList?.ITC.map((res) => (
															<Space key={res.label}>
																<Upload
																	name={res.name}
																	beforeUpload={() => false}
																	onChange={(file) =>
																		handleuploadOrder(
																			file,
																			res.name,
																			setFieldValue
																			// hasMultiple
																		)
																	}
																	listType="picture"
																	maxCount={1}
																	accept="text/csv"
																	className="uploadspage-antd"
																>
																	<Button
																		icon={<UploadOutlined />}
																		style={{
																			backgroundColor:
																				'#CA5010',
																			color: '#fff',
																		}}
																	>
																		Upload {res.label} File
																	</Button>
																</Upload>
															</Space>
														))}
													</Flex>
												)}

											{/* Upload for NESL */}
											{values?.brand?.includes('NESL') &&
												uploadType === 'picklite_order_adapter' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{OrderAdapterUploadList?.NESL?.map(
															(resNesl) => (
																<Space key={resNesl.label}>
																	<Upload
																		name={resNesl.name}
																		beforeUpload={() => false}
																		onChange={(file) =>
																			handleuploadOrder(
																				file,
																				resNesl.name,
																				setFieldValue
																				// hasMultiple
																			)
																		}
																		listType="picture"
																		maxCount={1}
																		accept="text/csv"
																		className="uploadspage-antd"
																	>
																		<Button
																			icon={
																				<UploadOutlined />
																			}
																			style={{
																				backgroundColor:
																					'#CA5010',
																				color: '#fff',
																			}}
																		>
																			{`Upload ${resNesl.label} File`}
																		</Button>
																	</Upload>
																</Space>
															)
														)}
													</Flex>
												)}

											{/* Upload for GDJ */}
											{values?.brand?.includes('GDJ') &&
												uploadType === 'picklite_order_adapter' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{OrderAdapterUploadList?.GDJ?.map(
															(resGd) => (
																<Space key={resGd.label}>
																	<Upload
																		name={resGd.name}
																		beforeUpload={() => false}
																		onChange={(file) =>
																			handleuploadOrder(
																				file,
																				resGd.name,
																				setFieldValue
																				// hasMultiple
																			)
																		}
																		listType="picture"
																		maxCount={1}
																		accept="text/csv"
																		className="uploadspage-antd"
																	>
																		<Button
																			icon={
																				<UploadOutlined />
																			}
																			style={{
																				backgroundColor:
																					'#CA5010',
																				color: '#fff',
																			}}
																		>
																			{`Upload ${resGd.label} File`}
																		</Button>
																	</Upload>
																</Space>
															)
														)}
													</Flex>
												)}

											{/* HUL has 3 uploads therefore seperate code */}
											{/* Upload for HUL */}
											{values?.brand?.includes('HUL') &&
												uploadType === 'picklite_order_adapter' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{OrderAdapterUploadList?.HUL.map((res1) => (
															<Space key={res1.label}>
																<Upload
																	name={res1.name}
																	beforeUpload={() => false}
																	onChange={(file) =>
																		handleuploadOrder(
																			file,
																			res1.name,
																			setFieldValue
																			// hasMultiple
																		)
																	}
																	listType="picture"
																	maxCount={1}
																	accept="text/csv"
																	className="uploadspage-antd"
																>
																	<Button
																		icon={<UploadOutlined />}
																		style={{
																			backgroundColor:
																				'#CA5010',
																			color: '#fff',
																		}}
																	>
																		Upload {res1.label} File
																	</Button>
																</Upload>
															</Space>
														))}
													</Flex>
												)}

											{/** BRIT has 3 uploads */}
											{values?.brand?.includes('BRIT') &&
												uploadType === 'picklite_order_adapter' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{OrderAdapterUploadList?.BRIT.map(
															(res2) => (
																<Space key={res2.label}>
																	<Upload
																		name={res2.name}
																		beforeUpload={() => false}
																		onChange={(file) =>
																			handleuploadOrder(
																				file,
																				res2.name,
																				setFieldValue
																				// hasMultiple
																			)
																		}
																		listType="picture"
																		maxCount={1}
																		accept="text/csv"
																		className="uploadspage-antd"
																	>
																		<Button
																			icon={
																				<UploadOutlined />
																			}
																			style={{
																				backgroundColor:
																					'#CA5010',
																				color: '#fff',
																			}}
																		>
																			Upload {res2.label} File
																		</Button>
																	</Upload>
																</Space>
															)
														)}
													</Flex>
												)}

											{/** Nivea has 2 uploads */}
											{values?.brand?.includes('NVA') &&
												(uploadType === 'picklite_order_adapter' ||
													uploadType === 'sales_return') && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{getListType()?.NVA.map((res3) => (
															<Space key={res3.label}>
																<Upload
																	name={res3.name}
																	beforeUpload={() => false}
																	onChange={(file) =>
																		handleuploadOrder(
																			file,
																			res3.name,
																			setFieldValue
																			// hasMultiple
																		)
																	}
																	listType="picture"
																	maxCount={1}
																	accept="text/csv"
																	className="uploadspage-antd"
																>
																	<Button
																		icon={<UploadOutlined />}
																		style={{
																			backgroundColor:
																				'#CA5010',
																			color: '#fff',
																		}}
																	>
																		Upload {res3.label} File
																	</Button>
																</Upload>
															</Space>
														))}
													</Flex>
												)}
											{values?.brand?.includes('LOREAL') &&
												uploadType === 'sales_return' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{getListType()?.LOREAL.map((res3) => (
															<Space key={res3.label}>
																<Upload
																	name={res3.name}
																	beforeUpload={() => false}
																	onChange={(file) =>
																		handleuploadOrder(
																			file,
																			res3.name,
																			setFieldValue
																			// hasMultiple
																		)
																	}
																	listType="picture"
																	maxCount={1}
																	accept="text/csv"
																	className="uploadspage-antd"
																>
																	<Button
																		icon={<UploadOutlined />}
																		style={{
																			backgroundColor:
																				'#CA5010',
																			color: '#fff',
																		}}
																	>
																		Upload {res3.label} File
																	</Button>
																</Upload>
															</Space>
														))}
													</Flex>
												)}

											{/** Dabur has 2 uploads */}
											{values?.brand?.includes('DBR') &&
												uploadType === 'picklite_order_adapter' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{OrderAdapterUploadList?.DBR.map((res4) => (
															<Space key={res4.label}>
																<Upload
																	name={res4.name}
																	beforeUpload={() => false}
																	onChange={(file) =>
																		handleuploadOrder(
																			file,
																			res4.name,
																			setFieldValue
																			// hasMultiple
																		)
																	}
																	listType="picture"
																	maxCount={1}
																	accept="text/csv"
																	className="uploadspage-antd"
																>
																	<Button
																		icon={<UploadOutlined />}
																		style={{
																			backgroundColor:
																				'#CA5010',
																			color: '#fff',
																		}}
																	>
																		Upload {res4.label} File
																	</Button>
																</Upload>
															</Space>
														))}
													</Flex>
												)}
											{[
												'BRIT',
												'GLDP',
												'RB',
												'RKTHL',
												'RKT',
												'CLGT',
												'GDJGT',
												'GDJMT',
												'GDJ',
												'DBR',
												'ITC',
												'TATA',
												'SNPR',
												'KELGS',
												'MDLZ',
												'WPRO',
											].some((brand) => values?.brand?.includes(brand)) &&
												(uploadType === 'pack_master' ||
													uploadType === 'sales_return') && (
													<Space
														direction="vertical"
														style={{
															width: '50%',
														}}
														size="large"
													>
														<Upload
															name="excel"
															beforeUpload={() => false}
															onChange={(file) =>
																handleuploadOrder(
																	file,
																	'excel',
																	setFieldValue
																	// hasMultiple
																)
															}
															listType="picture"
															maxCount={1}
															accept="text/csv"
															className="uploadspage-antd"
														>
															<Button
																icon={<UploadOutlined />}
																style={{
																	backgroundColor: '#CA5010',
																	color: '#fff',
																	width: '220px',
																}}
															>
																Upload a File
															</Button>
														</Upload>
													</Space>
												)}

											{/* Sales Return Upload for HUL */}
											{values?.brand?.includes('HUL') &&
												uploadType === 'sales_return' && (
													<Flex
														alignItems="center"
														justifyContent="space-around"
														width="100vw"
													>
														{SalesReturnUploadList?.HUL.map((res3) => (
															<Space key={res3.label}>
																<Upload
																	name={res3.name}
																	beforeUpload={() => false}
																	onChange={(file) =>
																		handleuploadOrder(
																			file,
																			res3.name,
																			setFieldValue
																			// hasMultiple
																		)
																	}
																	listType="picture"
																	maxCount={1}
																	accept="text/csv"
																	className="uploadspage-antd"
																>
																	<Button
																		icon={<UploadOutlined />}
																		style={{
																			backgroundColor:
																				'#CA5010',
																			color: '#fff',
																		}}
																	>
																		Upload {res3.label} File
																	</Button>
																</Upload>
															</Space>
														))}
													</Flex>
												)}
											{/* Upload for Single file */}
											{((!values?.brand?.includes('HUL') &&
												!values?.brand?.includes('BRIT') &&
												!values?.brand?.includes('ITC') &&
												!values?.brand?.includes('NVA') &&
												!values?.brand?.includes('DBR') &&
												!values?.brand?.includes('NESL') &&
												!values?.brand?.includes('GDJ') &&
												uploadType === 'picklite_order_adapter') ||
												uploadType === 'picklite_grn') && (
												<Space
													direction="vertical"
													style={{
														width: '50%',
													}}
													size="large"
												>
													<Upload
														name="excel"
														beforeUpload={() => false}
														onChange={(file) =>
															handleuploadOrder(
																file,
																'excel',
																setFieldValue
																// hasMultiple
															)
														}
														listType="picture"
														maxCount={1}
														accept="text/csv"
														className="uploadspage-antd"
													>
														<Button
															icon={<UploadOutlined />}
															style={{
																backgroundColor: '#CA5010',
																color: '#fff',
																width: '220px',
															}}
														>
															Upload a File
														</Button>
													</Upload>
												</Space>
											)}
											{uploadType === 'picklite_old_invoice' && (
												<Space
													direction="vertical"
													style={{
														width: '50%',
													}}
													size="large"
												>
													<Upload
														name="excel"
														beforeUpload={() => false}
														onChange={(file) =>
															handleuploadOrder(
																file,
																'excel',
																setFieldValue
																// hasMultiple
															)
														}
														listType="picture"
														maxCount={1}
														accept=".xlsx"
														className="uploadspage-antd"
													>
														<Button
															icon={<UploadOutlined />}
															style={{
																backgroundColor: '#CA5010',
																color: '#fff',
																width: '220px',
															}}
														>
															Upload a File
														</Button>
													</Upload>
												</Space>
											)}
										</Flex>
										{errors?.excel && <ErrorText>File is required</ErrorText>}

										{/* Errors for ITC */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.master &&
													values?.brand?.includes('ITC') && (
														<ErrorText>master is required</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.sales &&
													values?.brand?.includes('ITC') && (
														<ErrorText>sales is required</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.credit_adj &&
													values?.brand?.includes('ITC') && (
														<ErrorText>
															ITC Itemwise sales returns is required
														</ErrorText>
													)}
											</Box>
										</Flex>

										{/* Errors for NESL */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.sales &&
													values?.brand?.includes('NESL') && (
														<ErrorText>
															Sales Order File required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.billwise &&
													values?.brand?.includes('NESL') && (
														<ErrorText>
															Billwise Productwise Sales File required
														</ErrorText>
													)}
											</Box>

											<Box>
												{errors?.credit_adj &&
													values?.brand?.includes('NESL') && (
														<ErrorText>
															Sales Report Bill Wise File required
														</ErrorText>
													)}
											</Box>
										</Flex>

										{/* Errors for GDJ */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.sales &&
													values?.brand?.includes('GDJ') && (
														<ErrorText>
															Sales Order Report (SO Report) File
															required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.credit_adj &&
													values?.brand?.includes('GDJ') && (
														<ErrorText>
															Credit Adjustment File required
														</ErrorText>
													)}
											</Box>
										</Flex>

										{/* Error for Sales Return HUL */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.master &&
													values?.brand?.includes('HUL') && (
														<ErrorText>
															Sales Return Details File is Required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.gstr && values?.brand?.includes('HUL') && (
													<ErrorText>
														GST Return File is Required
													</ErrorText>
												)}
											</Box>
										</Flex>

										{/* Error for Sales Return NVA */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.sales_return_upload &&
													(values?.brand?.includes('NVA') ||
														values?.brand?.includes('LOREAL')) && (
														<ErrorText>
															Sales Return File is Required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.sales_return_product &&
													(values?.brand?.includes('NVA') ||
														values?.brand?.includes('LOREAL')) && (
														<ErrorText>
															Sales Return Product File is Required
														</ErrorText>
													)}
											</Box>
										</Flex>

										{/** Errors for  NVA & DBR */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.so_report &&
													(values?.brand?.includes('DBR') ||
														values?.brand?.includes('NVA')) && (
														<ErrorText>
															Sales Order Report (SO Report) required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.inv_report &&
													values?.brand?.includes('NVA') && (
														<ErrorText>
															Billwise Retailer sales required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.inv_report &&
													values?.brand?.includes('DBR') && (
														<ErrorText>
															Sales value Report Billwise Required
														</ErrorText>
													)}
											</Box>
										</Flex>

										{/** Errors for BRIT */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.so_report &&
													values?.brand?.includes('BRIT') && (
														<ErrorText>
															Sales Order Report (SO Report) required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.inv_report &&
													values?.brand?.includes('BRIT') && (
														<ErrorText>
															Invoice Report Required
														</ErrorText>
													)}
											</Box>
											<Box>
												{errors?.register &&
													values?.brand?.includes('BRIT') && (
														<ErrorText>
															Sales Register Required
														</ErrorText>
													)}
											</Box>
										</Flex>

										{/* Errors for HUL */}
										<Flex alignItems="center" justifyContent="space-around">
											<Box>
												{errors?.bill &&
													uploadType === 'picklite_order_adapter' && (
														<ErrorText>Bill is required</ErrorText>
													)}
											</Box>
											{/* {errors?.payout && (
										<Text
											mt="0.5rem"
											textAlign="center"
											style={{ color: theme.colors.error }}
										>
											payout is required
										</Text>
									)} */}
											<Box>
												{errors?.sales_register &&
													uploadType === 'picklite_order_adapter' && (
														<ErrorText>
															Sales register is required
														</ErrorText>
														// <ErrorText data="Sales register is required" />
													)}
											</Box>
											<Box>
												{errors?.shogun &&
													uploadType === 'picklite_order_adapter' && (
														<ErrorText>shogun is required</ErrorText>
													)}
											</Box>
										</Flex>
										<Box mt="16px">
											<BtnWrap>
												<StyledSubmitButton disabled={!uploadOrderAdapter}>
													Submit
												</StyledSubmitButton>
											</BtnWrap>
										</Box>
									</>
								)}
							</Box>
						)}

						<Debug />
					</Form>
				);
			}}
		</Formik>
	);
}

UploadPageFormNew.propTypes = {
	toggleState: PropTypes.array,
	handleTogglePaginate: PropTypes.func,
	toggleuploadType: PropTypes.array,
};
