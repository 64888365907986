const billingExecutive = 'Billing Executive';
const transportManager = 'Transport Manager';
const cashier = 'Cashier';
const additionalResource = 'Additional Resource';
const fcManager = 'FC Manager';
const picking = 'Picking';
const supportTeam = 'Support Team';
const deliveryBoy = 'Delivery Boy';
const viewAccess = 'View Access';
const segregator = 'Segregator';
const systemAdmin = 'System Admin';
const finance = 'Finance';

export const getUnAvailablePermissions = (requiredPermissions, user) => {
	const permissions_str = user?.all_permissions;
	if (permissions_str) {
		const permissions = new Set(permissions_str.split(','));
		return requiredPermissions.filter((x) => !permissions.has(x));
	}

	return requiredPermissions;
};

export const checkAssignedGroups = (allowedGroups = [], user = {}, excludedroute = false) => {
	const groups_str = user?.groups;
	let isAllowed = false;

	// systemAdmin, supportTeam are same as admin user so we have used the second condition
	// to eliminate some routes
	if (user?.is_superuser || [systemAdmin].includes(user?.groups?.[0]?.name)) {
		return (
			(user?.is_superuser || [systemAdmin].includes(user?.groups?.[0]?.name)) &&
			!excludedroute
		);
	}

	if (groups_str) {
		const groups = groups_str.map((group) => group.name);
		isAllowed = groups.some((x) => allowedGroups.indexOf(x) >= 0);
	}

	return isAllowed;
};

const CashierPermissions = {
	verification: [systemAdmin, segregator],
	allocation: [systemAdmin, segregator],
	collectionSettlement: [systemAdmin, cashier],
	bankSettlement: [systemAdmin, cashier],
	bankSettlement_create: [systemAdmin, cashier],
	additionalSettlement: [systemAdmin, cashier],
	checkBounceCashier: [systemAdmin, cashier, fcManager],
};

export const UserPersmission = {
	dashboard: [
		systemAdmin,
		billingExecutive,
		transportManager,
		cashier,
		additionalResource,
		fcManager,
		picking,
		supportTeam,
		viewAccess,
		segregator,
		deliveryBoy,
		finance,
	],
	/** -------------------------------------------------
	 * Order Management
	------------------------------------------------- */
	salesOrder_list: [
		systemAdmin,
		billingExecutive,
		fcManager,
		transportManager,
		viewAccess,
		supportTeam,
		finance,
	],
	salesOrderunblock_list: [finance],
	salesOrder_create: [systemAdmin, billingExecutive, transportManager],
	salesOrder_show: [
		systemAdmin,
		billingExecutive,
		fcManager,
		transportManager,
		viewAccess,
		supportTeam,
		finance,
	],
	salesReturn_list: [
		systemAdmin,
		billingExecutive,
		fcManager,
		transportManager,
		viewAccess,
		supportTeam,
	],
	salesReturn_create: [systemAdmin],
	salesReturn_show: [systemAdmin, billingExecutive, fcManager, viewAccess],
	/** -------------------------------------------------
	 * Logistics Management
	------------------------------------------------- */
	deliveryAllocation_list: [
		systemAdmin,
		billingExecutive,
		fcManager,
		transportManager,
		viewAccess,
		supportTeam,
	],
	deliveryAllocation_create: [systemAdmin, transportManager],
	deliveryAllocation_show: [
		systemAdmin,
		billingExecutive,
		fcManager,
		transportManager,
		viewAccess,
		supportTeam,
	],
	returnToFC_create: [systemAdmin, transportManager, finance],
	returnToFC_details: [
		systemAdmin,
		billingExecutive,
		fcManager,
		transportManager,
		viewAccess,
		supportTeam,
	],
	returnToFC_validate: [systemAdmin, billingExecutive, fcManager, transportManager, supportTeam],
	/** -------------------------------------------------
	 * Warehouse Management
	------------------------------------------------- */
	wmslogs_list: [
		systemAdmin,
		billingExecutive,
		fcManager,
		viewAccess,
		supportTeam,
		transportManager,
	],
	wmslogs_create: [systemAdmin, billingExecutive, fcManager, supportTeam],
	pologs_list: [
		systemAdmin,
		billingExecutive,
		fcManager,
		viewAccess,
		supportTeam,
		transportManager,
	],
	pologs_create: [systemAdmin, billingExecutive, fcManager, supportTeam],
	grn_list: [systemAdmin, supportTeam, billingExecutive, fcManager, viewAccess, transportManager],
	grn_show: [systemAdmin, supportTeam, billingExecutive, fcManager, viewAccess, transportManager],
	grn_create: [systemAdmin, billingExecutive, fcManager, transportManager],
	grn_verify: [systemAdmin],
	/** -------------------------------------------------
	 * Onboarding
	------------------------------------------------- */
	company_list: [systemAdmin, supportTeam],
	company_create: [systemAdmin, supportTeam],
	client_list: [systemAdmin, supportTeam],
	client_create: [systemAdmin, supportTeam],
	brand_list: [systemAdmin, supportTeam],
	brand_create: [systemAdmin, supportTeam],
	fc_list: [systemAdmin, supportTeam],
	fc_create: [systemAdmin, supportTeam],
	store_list: [systemAdmin, supportTeam],
	store_create: [systemAdmin, supportTeam],
	storeCategory_list: [systemAdmin, supportTeam],
	storeCategory_create: [systemAdmin, supportTeam],
	user_list: [systemAdmin, supportTeam],
	user_create: [systemAdmin, supportTeam],
	salesman_list: [systemAdmin, supportTeam],
	salesman_create: [systemAdmin, supportTeam],
	masters_show: [systemAdmin, supportTeam],
	bank_list: [systemAdmin, supportTeam],
	bank_create: [systemAdmin, supportTeam],
	pincode_list: [systemAdmin],
	/** -------------------------------------------------
	 * Cheque Bounce
	------------------------------------------------- */
	chequeBounce: [systemAdmin, finance],
	cheque_create: [systemAdmin, finance],
	addChequeBounce: [systemAdmin, finance, fcManager],
	chequeBounceAdmin: [systemAdmin],
	/** -------------------------------------------------
	 * Adapter Uploads / Uploads / Uploads View
	------------------------------------------------- */
	// Adapter Uploads / Uploads
	upload_list: [
		systemAdmin,
		billingExecutive,
		viewAccess,
		supportTeam,
		fcManager,
		transportManager,
	],
	upload_list_create: [systemAdmin, billingExecutive, supportTeam, fcManager],
	// Uploads View
	upload_show: [systemAdmin, billingExecutive, fcManager, viewAccess, supportTeam, fcManager],
	/** -------------------------------------------------
	 * Show in Dashboard
	------------------------------------------------- */
	onboarding: [systemAdmin, supportTeam],
	/** -------------------------------------------------
	 * Dashboard
	------------------------------------------------- */
	downloads: [
		systemAdmin,
		transportManager,
		billingExecutive,
		viewAccess,
		fcManager,
		supportTeam,
		segregator,
		cashier,
		finance,
	],

	/** -------------------------------------------------
	 * 
	------------------------------------------------- */

	orderAdapter: [systemAdmin],
	inventory_currentInventory: [systemAdmin, billingExecutive, fcManager, supportTeam],
	inventory_unsaleableInventory: [systemAdmin, billingExecutive, fcManager, supportTeam],
	collections_list: [
		systemAdmin,
		billingExecutive,
		transportManager,
		cashier,
		segregator,
		supportTeam,
	],
	transactionLedger_list: [systemAdmin, finance],
	...CashierPermissions,
	// Need to deleted if not used
	// upload_list_old: [systemAdmin, viewAccess, supportTeam],
	// upload_excel: [systemAdmin, billingExecutive, supportTeam, fcManager],
};
