import React from 'react';
import PropTypes from 'prop-types';
import { CashierSalesManStatsHeader, ChequeList } from 'components/pages/cashier/block-edits-v2';
export function ChequeVerification({ handleSaveClick, stepsData }) {
	return (
		<div>
			<CashierSalesManStatsHeader />
			<ChequeList handleSaveClick={handleSaveClick} stepsData={stepsData} />
		</div>
	);
}
ChequeVerification.propTypes = {
	handleSaveClick: PropTypes.func,
	stepsData: PropTypes.any,
};
