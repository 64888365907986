import React, { useEffect, useState } from 'react';
import { Modal, Radio, Space } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Text } from 'atoms';
import { fetchReasonsApiCall } from 'store/fulfilment/salesOrderSlice';

const StyledButton = styled(Button)`
	width: 240px;
	height: 40px;
	border-radius: 4px;
	background-color: #387a26;
	margin-top: 20px;
	margin-left: 0;
`;
const StyledText = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 10px;
	text-align: center;
`;

const StyledSpan = styled.span`
	font-size: 14px;
	font-weight: 400;
	color: ${(props) => (props.selected ? '#BC5827' : 'black')};
`;

const StyledModal = styled(Modal)`
	.ant-radio-checked .ant-radio-inner {
		border-color: #ca5010;
	}
	.ant-radio-inner::after {
		background-color: #ca5010;
	}
`;

const StyledRadio = styled(Radio)`
	width: 100%;
	border: 1px solid #e4e4e4;
	padding: 20px;
	border-radius: 4px;
	background-color: ${(props) => (props.selected ? '#FFF9F6' : '#fff')};
	border-color: ${(props) => (props.selected ? '#BC5827' : '#E4E4E4')};
`;

export default function UnblockReasonModal({
	modalVisible = true,
	width = '524px',
	handleOk,
	rowSelect,
	handleCancel,
}) {
	const [value, setValue] = useState(null);
	const [reasons, setReasons] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		fetchReasons();
	}, []);

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const fetchReasons = () => {
		dispatch(fetchReasonsApiCall())
			.then((res) => {
				setReasons(res?.data);
			})
			.catch(() => console.log('error occcured'));
	};

	return (
		<StyledModal
			width={width}
			visible={modalVisible}
			footer={null}
			onCancel={() => {
				setValue(null);
				handleCancel();
			}}
		>
			<Flex alignItems="center" flexDirection="column">
				<Box>
					<StyledText>
						You have selected {rowSelect?.length}
						{rowSelect.length > 1 ? ' invoices' : ' invoice'} to Unblock
					</StyledText>
					<StyledText style={{ color: '#6B6B6B' }}>
						Please Select Reason for Unblocking Invoices
					</StyledText>
				</Box>

				<Radio.Group onChange={onChange} value={value} style={{ width: '100%' }}>
					<Space direction="vertical" style={{ width: '100%' }}>
						{reasons?.map((reason) => (
							<StyledRadio
								value={reason?.id}
								key={reason?.id}
								selected={value === reason?.id}
							>
								<StyledSpan selected={value === reason?.id}>
									{reason?.reason}
								</StyledSpan>
							</StyledRadio>
						))}
					</Space>
				</Radio.Group>

				<StyledButton
					variant="primary"
					onClick={() => {
						setValue(null);
						handleOk(value);
					}}
					disabled={!value}
				>
					Submit
				</StyledButton>
			</Flex>
		</StyledModal>
	);
}

UnblockReasonModal.propTypes = {
	modalVisible: PropTypes.bool,
	width: PropTypes.number,
	handleOk: PropTypes.func,
	rowSelect: PropTypes.any,
	handleCancel: PropTypes.func,
};
