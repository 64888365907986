import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message as toaster } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SalesManStats } from 'components/pages/segregator/block-edits-v2';
import InvoiceCardView from 'components/pages/segregator/block-edits-v2/invoiceCardView';
import { getInvoicesList, saveSegregatorVerification } from 'store/invoiceSlice';
import { Flex, Button } from 'atoms';
import { useURL } from 'hooks';
import { getGreenRedTicks } from 'utils/helper';

export default function SalesPerson() {
	const dispatch = useDispatch();
	const [invoices, setInvoices] = useState([]);
	const [statsData, setStatsData] = useState({});
	const [isValid, setIsValid] = useState({
		somethingChecked: false,
		isAllAreVerified: false,
		isPending: false,
	});
	const urlData = useURL();
	const navigate = useNavigate();
	const { role } = useSelector((state) => state.auth);
	const { dateTrigger, tab } = useSelector((state) => state.invoice);
	const [rejectedInvoices, setRejectedInvoices] = useState(false);

	useEffect(() => {
		/**
		 * if some invoices are verified, rejected then its available for save
		 */
		const somethingChecked = invoices?.every(
			(item) => item?.verification_checked || item?.rejection_checked
		);
		/**
		 * if all invoices are verified, then disable the save button
		 */
		const isAllAreVerified = invoices?.every((item) => item?.disabled);

		/**
		 * if all invoices are verified, then disable the save button
		 */
		const isPending = invoices?.some((item) => item?.status === 'Pending');

		setIsValid({
			somethingChecked,
			isAllAreVerified,
			isPending,
		});
	}, [invoices]);

	useEffect(() => {
		/**
		 * On Segregator login only this is available
		 */
		dispatch(getInvoicesList(urlData, rejectedInvoices)).then((res) => {
			res?.data?.forEach((element) => {
				/**
				 * If already verified by segregator (green tick) disable that
				 */
				element.disabled =
					element?.verified_by_segregator_status === 'VerifiedBySegregator';
				element.verification_checked =
					element?.verified_by_segregator_status === 'VerifiedBySegregator';
				// (element?.verified_by_segregator_status === 'Pending' &&
				// 	element?.status === 'No Bill Back' &&
				// 	element?.current_outstanding_amount === '0.0000');

				element.rejection_checked =
					element?.verified_by_segregator_status === 'RejectedBySegregator';
				element.rejectReason = element?.is_invoice_missing === '1' ? 'Invoice missing' : '';
			});
			setInvoices(res?.data);
			setStatsData(res?.data_summary);
		});
	}, [dispatch, urlData, role, dateTrigger, rejectedInvoices]);

	function handleVerifyReject(record, type = 'verify', rejectReason = '') {
		const records = invoices.map((item) => {
			if (item?.invoice_no === record?.invoice_no) {
				return getGreenRedTicks(type, item, rejectReason);
			}
			return item;
		});
		setInvoices([...records]);
	}

	function handleSaveSegregatorVerification() {
		if (isValid?.somethingChecked) {
			const summary = invoices?.map((item) => ({
				invoice_id: item.collection_invoice_id,
				invoice_checked: item?.verification_checked || item?.rejection_checked,
				verification_checked: item.verification_checked,
				rejection_checked: item.rejection_checked,
				is_invoice_missing: item.rejectReason === 'Invoice missing',
				...(item.rejectReason && {
					reject_remark: {
						remark_status: 'INVOICE_REJECT',
						brand_id: urlData.brand_id,
						remark: item.rejectReason,
						unique_key_code: '',
					},
				}),
			}));
			const data = {
				tab,
				collected_person_id: urlData?.collected_person_id,
				allocation_date_time: invoices?.[0]?.allocation_date_time,
				invoices: summary,
			};
			dispatch(saveSegregatorVerification(data)).then(() => {
				navigate('/settlement');
			});
		} else {
			toaster.error('Please select atleast one invoice .');
		}
	}

	const filterRejectedInvoices = () => {
		setRejectedInvoices(!rejectedInvoices);
	};

	return (
		<div>
			<Flex justifyContent="space-between" alignItems="center">
				<SalesManStats data={statsData} />
			</Flex>
			{/* <InvoicesList
				invoices={invoices}
				handleVerifyReject={handleVerifyReject}
				showRejection={!isValid?.isAllAreVerified}
				disabled={
					isValid?.isPending || (urlData?.return_status === 'Pending' && tab === '2')
				}
				// on deliverer tab disable the verificatoin if return_status is Pending, this data will come from CDMS Return to FC
			/> */}
			<InvoiceCardView
				filterRejectedInvoices={filterRejectedInvoices}
				setInvoices={setInvoices}
				salesmanData={statsData}
				invoices={invoices}
				handleVerifyReject={handleVerifyReject}
				showRejection={!isValid?.isAllAreVerified}
				disabled={
					isValid?.isPending || (urlData?.return_status === 'Pending' && tab === '2')
				}
				segStatus={urlData?.verified_by_segregator_status}
			/>
			<Flex justifyContent="flex-end" mt="2rem">
				<Button
					onClick={handleSaveSegregatorVerification}
					disabled={!isValid?.somethingChecked || isValid?.isAllAreVerified}
				>
					{isValid?.isAllAreVerified ? 'Already Verified' : 'Save'}
				</Button>
			</Flex>
		</div>
	);
}
