import { React } from 'react';
import { Switch } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledToggle = styled(Switch)`
	// position: absolute;
	// left: 250px;
	// top: 31px;
	background-color: #ca5010;
`;

export default function RejectedInvoiceToggle({ filterRejectedInvoices }) {
	const onChange = (checked) => {
		console.log(`switch to ${checked}`);
		filterRejectedInvoices();
	};

	return <StyledToggle onChange={onChange} />;
}

RejectedInvoiceToggle.propTypes = {
	filterRejectedInvoices: PropTypes.func,
};
