import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as SVGICONS from 'utils/Svg';
import { Image, Box, Text } from 'atoms';
import { Rupee } from 'components';
import invoiceMissing from 'assets/svg/invoice-missing.svg';
import { RemarksIcon, InvoiceStatus, Verification } from 'components/pages/segregator';
import DateFormat from 'components/DateFormat';
import { getDateDiff } from 'utils/dateUtils';

const { Panel } = Collapse;
export default function InvoiceCardViewCashier({
	invoices,
	handleVerifyReject = () => {},
	showRejection = false,
	disabled = false,
	searchText,
}) {
	const { role } = useSelector((state) => state.auth);
	const [invoiceList, setInvoiceList] = useState(invoices);
	useEffect(() => {
		if (searchText?.trim()?.length === 0) {
			setInvoiceList(() => invoices);
			return;
		}
		setInvoiceList(() =>
			invoices.filter((item) => item.invoice_no.toLowerCase()?.includes(searchText))
		);
	}, [searchText, invoices]);

	return (
		<Box>
			{invoiceList?.map((invoice) => (
				<Wrapper key={invoice?.invoice_no}>
					<Card>
						<UpperCard>
							<Box width="200px">
								<Heading>Invoice number</Heading>
								<DataOne>{invoice?.invoice_no}</DataOne>
								<DataTwo>
									<DateFormat date={invoice?.invoice_date} format="DMY" />
								</DataTwo>
							</Box>
							{/* Don't show Age for Delivery Person */}
							{!invoice?.delivery_boy_id && (
								<Box ml="34px" width="75px">
									<Heading>Age</Heading>
									<DataOne>{getDateDiff(invoice?.invoice_date)} Days</DataOne>
								</Box>
							)}
							<Box ml="34px" width="400px">
								<Heading>Store name</Heading>
								<DataOne>{invoice?.storeName}</DataOne>
								<DataTwo>{invoice?.storeCode}</DataTwo>
							</Box>
							<Box ml="40px" width="200px">
								<Heading>Invoice Value</Heading>
								<DataOne>
									<Rupee>{Number(invoice?.initial_outstanding_amount)}</Rupee>
								</DataOne>
							</Box>
							<Box ml="40px" width="200px">
								<Heading>Collected Value</Heading>
								<DataOne>
									<Rupee>
										{Number(invoice?.initial_outstanding_amount) -
											Number(invoice?.current_outstanding_amount)}
									</Rupee>
								</DataOne>
							</Box>
							<Box ml="34px" width="200px">
								<Heading>Outstanding</Heading>
								<DataOne>
									<Rupee>{Number(invoice?.current_outstanding_amount)}</Rupee>
								</DataOne>
							</Box>
							<Box ml="34px">
								<Heading>Invoice Status</Heading>
								<DataOne>
									<InvoiceStatus invoice={invoice} />
								</DataOne>
							</Box>
							<Box ml="34px">
								<HeadingCenter>Action</HeadingCenter>
								<DataOne>
									{invoice?.is_invoice_missing === '1' ? (
										<Tooltip title="Invoice Missing">
											<Image
												src={invoiceMissing}
												alt="invoiceMissing"
												width={30}
												height={30}
											/>
										</Tooltip>
									) : (
										<Verification
											record={invoice}
											handleVerifyReject={handleVerifyReject}
											showRejection={showRejection}
											remarksType="INVOICE_REJECT"
											disabled={
												role === 'Cashier' ||
												invoice?.disabled ||
												disabled ||
												false
											}
										/>
									)}
								</DataOne>
							</Box>
							<Box mt="21px" ml="34px">
								<RemarksIcon record={invoice} type="INVOICE" />
							</Box>
						</UpperCard>
						{invoice?.reason !== '' && (
							<SalesManReason>
								<SalesManReasonIcon>
									<SVGICONS.WarningIcon width={18} />
								</SalesManReasonIcon>
								<SalesManReasonLabel>Short Settlement Reason:</SalesManReasonLabel>
								{invoice?.reason}
							</SalesManReason>
						)}
						{invoice?.payment_detail_info?.length > 0 && (
							<Collapse
								expandIconPosition="right"
								bordered={false}
								defaultActiveKey={invoiceList?.map(
									(item) => item?.collection_invoice_id
								)}
							>
								<Panel
									header={<Text fontWeight="bold">Collection Mode</Text>}
									key={invoice?.collection_invoice_id}
								>
									<CollectionWrapper>
										{invoice?.payment_detail_info?.map((paymentDetails) => (
											<PaymentInfoBox key={paymentDetails?.payment_id}>
												<BorderDiv>
													{paymentDetails?.amount && (
														<ModeLower>
															{paymentDetails?.payment_type}:{' '}
															<DataFirst>
																<Rupee>
																	{paymentDetails?.amount}
																</Rupee>
															</DataFirst>
														</ModeLower>
													)}
													{paymentDetails?.reference_number && (
														<ModeLower>
															Reference no:{' '}
															<DataFirst>
																{paymentDetails?.reference_number}
															</DataFirst>
														</ModeLower>
													)}
													{paymentDetails?.bank_name && (
														<ModeLower>
															Bank:{' '}
															<DataFirst>
																{paymentDetails?.bank_name}
															</DataFirst>
														</ModeLower>
													)}
													{paymentDetails?.due_date && (
														<ModeLower>
															Due Date:{' '}
															<DataFirst>
																<DateFormat
																	date={paymentDetails?.due_date}
																	format="DMY"
																/>
															</DataFirst>
														</ModeLower>
													)}
												</BorderDiv>{' '}
											</PaymentInfoBox>
										))}
									</CollectionWrapper>
								</Panel>
							</Collapse>
						)}
					</Card>
				</Wrapper>
			))}
		</Box>
	);
}
InvoiceCardViewCashier.propTypes = {
	invoices: PropTypes.array,
	handleVerifyReject: PropTypes.func,
	showRejection: PropTypes.bool,
	disabled: PropTypes.bool,
	searchText: PropTypes.any,
};
export const SalesManReasonIcon = styled.div`
	width: 18px;
	margin-right: 6px;
	margin-top: -2px;
`;
export const SalesManReasonLabel = styled.div`
	color: #ca5010;
	margin-right: 12px;
`;
export const SalesManReason = styled.div`
	display: flex;
	align-items: center;
	background: #fff7f2;
	margin: 0 16px;
	padding: 12px 16px;
	border-radius: 4px;
	color: #000000;
	font-size: 16px;
`;
export const SectionHead = styled.div`
	margin-right: 20px;
	padding-top: 5px;
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	text-align: left;
	color: ${(props) => props.theme.colors.DavysGrey};
`;
export const Wrapper = styled.div`
	width: 100%;
	margin-top: 10px;
`;
export const Card = styled.div`
	box-sizing: border-box;
	width: 100%;
	top: 0px;
	background: ${(props) => props.theme.colors.white};
	border: 1px solid ${(props) => props.theme.colors.CulturedDark};
	border-radius: 4px;
	padding: 7px;
`;
export const UpperCard = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 12px 16px;
`;
export const Heading = styled.p`
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	text-align: left;
	color: ${(props) => props.theme.colors.DavysGrey};
`;
export const HeadingCenter = styled.p`
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	text-align: center;
	color: ${(props) => props.theme.colors.DavysGrey};
`;
export const DataOne = styled(Box)`
	font-size: 14px;
	font-weight: 600;
	line-height: 27px;
	text-align: left;
	color: ${(props) => props.theme.colors.BlackCoffee};
	margin: 7px 0px 7px 0px;
`;
export const DataTwo = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	text-align: left;
	color: ${(props) => props.theme.colors.DavysGrey};
`;
export const LowerCard = styled.div`
	border-top: 1px solid ${(props) => props.theme.colors.CulturedDark};
	padding: 12px;
	width: 100%;
`;
export const ModeLower = styled.p`
	font-size: 12px;
	font-weight: 400;
	line-height: 19px;
	text-align: left;
	color: ${(props) => props.theme.colors.DavysGrey};
	padding: 5px 15px 5px 15px;
	display: flex;
	&:first-child {
		padding-left: 0;
	}
`;
export const DataFirst = styled.span`
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	color: ${(props) => props.theme.colors.BlackCoffee};
	margin-left: 12px;
	text-transform: uppercase;
`;
export const CollectionWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;
export const BorderDiv = styled.div`
	border-right: 1px solid black;
	display: flex;
	margin: 3px 0;
`;
const PaymentInfoBox = styled.div`
	margin-right: 15px;
`;
