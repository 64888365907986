/* eslint-disable */
import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'atoms';

const dataObj = {
	Cheque: [
		{
			key: 'settled_inv',
			value: 'Settled Inv',
		},
		{
			key: 'amount',
			value: 'Cheque Amount',
		},
		{
			key: 'dueDate',
			value: 'Due Date',
		},
		{
			key: 'referenceNumber',
			value: 'Reference Number',
		},
		{
			key: 'bankName',
			value: 'Bank Name',
		},
	],
	UPI: [
		{
			key: 'settled_inv',
			value: 'Settled Inv',
		},
		{
			key: 'amount',
			value: 'UPI Amount',
		},
		{
			key: 'referenceNumber',
			value: 'Reference Number.',
		},
	],
	Cash: [
		{ key: 'old_amount', value: 'Old Amount' },
		{ key: 'updated_amount', value: 'Updated Amount' },
	],
};
function RejectedDetails({ payment_type, cashierData }) {
	function getColumn(item, dataType) {
		return (
			<Box style={{ marginRight: '20px' }} key={item.key}>
				<Text>{item.value}</Text>
				<Text>
					{item.key === 'settled_inv'
						? cashierData?.invoice_nos
						: cashierData?.[dataType]?.[item.key]}
				</Text>
			</Box>
		);
	}

	const getEditType = (type) => {
		let result = '';
		if (type == 'EDITAMOUNT') {
			result = 'Amount';
		} else if (type == 'EDITDATA') {
			result = 'Data';
		} else if (type == 'EDITAMOUNTORDATA') {
			result = 'Amount and Data';
		}
		return result;
	};
	return (
		<Box style={{ padding: '0px 20px 20px 0px', minWidth: '400px' }}>
			<Box
				style={{
					position: 'relative',
					borderBottom: '1px solid #E8E8E8',
					padding: '10px 0px 10px 80px',
				}}
			>
				<Box
					style={{
						background: '#0094FF',
						width: '68px',
						height: '68px',
						position: 'absolute',
						left: '0px',
						top: '10px',
						borderRadius: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<SyncOutlined style={{ color: 'white', fontSize: '32px' }} />
				</Box>
				<Text>Rejected {payment_type} Details</Text>
			</Box>
			<Box
				style={{
					paddingLeft: '80px',
					marginTop: '20px',
				}}
			>
				{payment_type === 'Cash' && (
					<Flex justifyContent="space-between">
						<Box>
							<Text style={{ color: 'red', marginBottom: '10px', fontWeight: '600' }}>
								Old Amount
							</Text>
							<Text> ₹{cashierData?.previous_data}</Text>
						</Box>
						<Box>
							<Text
								style={{
									color: '#0094FF',
									marginBottom: '10px',
									fontWeight: '600',
								}}
							>
								Updated Amount
							</Text>
							<Text> ₹{cashierData?.current_data}</Text>
						</Box>
					</Flex>
				)}

				{(payment_type === 'Cheque' || payment_type === 'UPI') && (
					<Box>
						<p style={{ color: 'red', marginBottom: '10px', fontWeight: '600' }}>
							Old Details
						</p>

						<Flex style={{ marginBottom: '20px' }}>
							{getColumn(
								{
									key: 'settled_inv',
									value: 'Settled Inv',
								},
								''
							)}
							{dataObj[payment_type].map(
								(item) =>
									cashierData?.previous_data[item?.key] &&
									getColumn(item, 'previous_data')
							)}
						</Flex>
						<Text style={{ color: '#0094FF', marginBottom: '10px', fontWeight: '600' }}>
							Updated Details
						</Text>
						<Flex style={{ marginBottom: '20px' }}>
							{getColumn(
								{
									key: 'settled_inv',
									value: 'Settled Inv',
								},
								''
							)}
							{dataObj[payment_type].map(
								(item) =>
									cashierData?.previous_data[item?.key] &&
									getColumn(item, 'current_data')
							)}
						</Flex>
					</Box>
				)}
			</Box>
		</Box>
	);
}
RejectedDetails.propTypes = {
	payment_type: PropTypes.string,
	cashierData: PropTypes.object,
};
export default RejectedDetails;
